<template>
  <div role="button" :class="style" @keyup.enter="$emit('clicked', day)" @click="$emit('clicked', day)"
    v-tooltip="label" :aria-label="day.day.getDate() + label" :aria-description="label" :tabindex="tabbable ? 0 : null">
    {{ day.day.getDate() }}
  </div>
</template>

<script>
export default {
  name: "DatePickerDay",
  props: {
    day: {
      required: true,
    },
    position: {
      required: true,
    },
    sch: {
      required: true,
    },
    reservations: {
      type: Object,
    },
    dayFull: {
      type: Boolean,
    },
    exceptions: {
      required: true,
    },
  },

  computed: {
    tabbable() {
      let copy = { ...this.day };

      return this.sch.schedule[this.conpensateDateTimezone(copy.day)] == undefined
        ? false
        : true
    },
    label() {
      let copy = { ...this.day };

      return this.sch.schedule[this.conpensateDateTimezone(copy.day)] == undefined
        ? "non selezionabile"
        : "selezionabile"
    },
    style() {
      let copy = { ...this.day };
      return [
        // `div${this.position + 1}`,
        { "prev-date": copy.type == "prev" },
        { "next-date": copy.type == "next" },
        { day: copy.type != "next" && copy.type != "prev" },
        // { selected: copy.type == "selected" },
        this.sch.schedule[this.conpensateDateTimezone(copy.day)] == undefined
          ? "unselectable"
          : "",
        // this.reservations &&
        // this.conpensateDateTimezone(copy.day) in this.reservations
        //   ? "reserved"
        //   : "",
        //   //giorni del passato
        // parseInt(String(copy.day.getTime() / 1000).substring(0, 10)) <
        // parseInt(String(this.today.getTime() / 1000).substring(0, 10))
        //   ? "unselectable"
        //   : "",

        {
          unselectable: this.dayFull,
        },
        // this.exceptions[this.conpensateDateTimezone(copy.day)] &&
        // this.exceptions[this.conpensateDateTimezone(copy.day)] ==
        //   "00:00-24:00"
        //   ? "unselectable"
        //   : "",

        // this.maxRange && this.maxRange < copy.day.getTime() ? 'unselectable' : ''
      ];
    },
  },

  methods: {
    conpensateDateTimezone(date) {
      let copy = new Date(date.getTime());
      let arr = copy.toLocaleDateString("en").split("/");
      arr[0] = arr[0] > 9 ? arr[0] : `0${arr[0]}`;
      arr[1] = arr[1] > 9 ? arr[1] : `0${arr[1]}`;
      let year = arr[2];
      let month = arr[0];
      let day = arr[1];
      return [year, month, day].join("-");
    },
  },
};
</script>

<style scoped>
.div1 {
  grid-area: 1 / 1 / 2 / 2;
}

.div2 {
  grid-area: 1 / 2 / 2 / 3;
}

.div3 {
  grid-area: 1 / 3 / 2 / 4;
}

.div4 {
  grid-area: 1 / 4 / 2 / 5;
}

.div5 {
  grid-area: 1 / 5 / 2 / 6;
}

.div6 {
  grid-area: 1 / 6 / 2 / 7;
}

.div7 {
  grid-area: 1 / 7 / 2 / 8;
}

.div8 {
  grid-area: 2 / 1 / 3 / 2;
}

.div9 {
  grid-area: 2 / 2 / 3 / 3;
}

.div10 {
  grid-area: 2 / 3 / 3 / 4;
}

.div11 {
  grid-area: 2 / 4 / 3 / 5;
}

.div12 {
  grid-area: 2 / 5 / 3 / 6;
}

.div13 {
  grid-area: 2 / 6 / 3 / 7;
}

.div14 {
  grid-area: 2 / 7 / 3 / 8;
}

.div15 {
  grid-area: 3 / 1 / 4 / 2;
}

.div16 {
  grid-area: 3 / 2 / 4 / 3;
}

.div17 {
  grid-area: 3 / 3 / 4 / 4;
}

.div18 {
  grid-area: 3 / 4 / 4 / 5;
}

.div19 {
  grid-area: 3 / 5 / 4 / 6;
}

.div20 {
  grid-area: 3 / 6 / 4 / 7;
}

.div21 {
  grid-area: 3 / 7 / 4 / 8;
}

.div22 {
  grid-area: 4 / 1 / 5 / 2;
}

.div23 {
  grid-area: 4 / 2 / 5 / 3;
}

.div24 {
  grid-area: 4 / 3 / 5 / 4;
}

.div25 {
  grid-area: 4 / 4 / 5 / 5;
}

.div26 {
  grid-area: 4 / 5 / 5 / 6;
}

.div27 {
  grid-area: 4 / 6 / 5 / 7;
}

.div28 {
  grid-area: 4 / 7 / 5 / 8;
}

.div29 {
  grid-area: 5 / 1 / 6 / 2;
}

.div30 {
  grid-area: 5 / 2 / 6 / 3;
}

.div31 {
  grid-area: 5 / 3 / 6 / 4;
}

.div32 {
  grid-area: 5 / 4 / 6 / 5;
}

.div33 {
  grid-area: 5 / 5 / 6 / 6;
}

.div34 {
  grid-area: 5 / 6 / 6 / 7;
}

.div35 {
  grid-area: 5 / 7 / 6 / 8;
}

.div36 {
  grid-area: 6 / 1 / 7 / 2;
}

.div37 {
  grid-area: 6 / 2 / 7 / 3;
}

.div38 {
  grid-area: 6 / 3 / 7 / 4;
}

.div39 {
  grid-area: 6 / 4 / 7 / 5;
}

.div40 {
  grid-area: 6 / 5 / 7 / 6;
}

.div41 {
  grid-area: 6 / 6 / 7 / 7;
}

.div42 {
  grid-area: 6 / 7 / 7 / 8;
}

div {
  width: 100%;
  background-color: var(--calendar-bg);
  font-weight: bold;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

div:hover {
  cursor: pointer;
}

div:focus:not(.unselectable) {
  outline: none;
  border: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prev-date,
.next-date {
  color: rgba(0, 0, 0, 1);
  opacity: 0.2;
  font-size: 0.7rem;
  position: relative;
}

.unselectable {
  /* opacity: 1; */
  color: rgba(0, 0, 0, 1);
  opacity: 0.2;
  pointer-events: none;
  position: relative;
  cursor: not-allowed;
}

.unselectable {
  font-weight: normal;
}

.oggi {
  color: red;
}

.reserved {
  color: rgba(212, 212, 34, 0.747);
}

.unselectable:focus {
  outline: none;
}
</style>