export const movesCalendar = {
    data() {
        return {
            dateString: null,
            nextDisabled: false,
            selected: null
        };
    },


    methods: {

        next() {
            console.log('next');
            let type;
            const copy = new Date(this.selected);
            if (!this.isMese || this.picker) {
                type = "day";

                const newSelected = new Date(copy.setDate(copy.getDate() + 1));
                console.log(newSelected);
                this.selected = newSelected;
            } else {
                console.log('next mese');
                type = "month";
                const newSelected = new Date(
                    new Date(new Date(copy.setDate(1)).setMonth(copy.getMonth() + 1))
                );
                if (
                    this.schedule.validità != null &&
                    new Date(this.schedule.validità) < newSelected
                ) {
                    this.nextDisabled = true;
                    return;
                }
                this.selected = newSelected;
            }

            //check validità calendario


            // this.nextDisabled = true;
            this.$emit("changed", { selected: this.selected, type });
            return { selected: this.selected, type };
        },
        prev() {
            let type;
            const copy = new Date(this.selected);
            let newSelected;
            if (!this.isMese || this.picker) {
                type = "day";
                newSelected = new Date(copy.setDate(copy.getDate() - 1));
                let check = new Date(copy.setDate(copy.getDate()));
                const now = new Date();
                if (now.setUTCHours(0, 0, 0, 0) > check) {
                    return;
                }
            } else {
                type = "month";
                newSelected = new Date(
                    new Date(copy.setMonth(copy.getMonth() - 1)).setDate(
                        new Date().getDate()
                    )
                );
                let check = new Date(copy.setDate(copy.getDate()));
                const now = new Date();
                if (now.setUTCHours(0, 0, 0, 0) > check) {
                    this.selected = new Date();
                    return this.$emit("changed", {
                        selected: this.selected,
                        type
                    });
                }
            }

            this.selected = newSelected;
            this.$emit("changed", { selected: this.selected, type });
            return { selected: this.selected, type };
        }
    }
};
