<template>
    <div class="h-100" v-if="lastDay && schedule">
        <message
            class="mb-0 text-center"
            :messageKey="'timezone'"
            v-if="differentTimezone"
        />

        <calendar-header
            :isLoading="loading"
            @changed="updateSelected"
            :isMese="!picker"
            @reset="picker = !picker"
            :selectedDate="selected"
            :schedule="schedule"
            data-cypress="calendarHeader"
        />
        <!-- Prima disponibilità -->
        <div
            class="prima-disp text-white text-center p-2"
            :style="{ background: color }"
            v-if="primaDisp && !picker && primaDisp.day"
        >
            <span>
                {{ $t("form.calendario.prima_disponibilità.from") }} :

                {{ primaDisp.string }}
                <span
                    data-cypress="primaDisp"
                    @click="openPicker(primaDisp)"
                    class="text-uppercase font-weight-bold"
                    v-tooltip="'clicca per mostrare la giornata'"
                    role="button"
                    tabindex="0"
                    @keyup.enter="openPicker(primaDisp)"
                >
                    {{ $t("form.calendario.prima_disponibilità.prenota") }}
                </span>
            </span>
        </div>
        <div
            class="prima-disp text-white text-center p-2"
            :style="{ background: color }"
            v-else-if="!primaDisp && !picker"
        >
            <span>
                {{ $t("form.calendario.prima_disponibilità.vuota") }}
            </span>
        </div>

        <!-- Prima disponibilità -->

        <div class="calendar" data-cypress="datePicker">
            <div :class="{ 'd-none': picker }" v-if="!picker" class="picker">
                <div class="weekdays">
                    <div class="d1">{{ $t("giorni[0]") }}</div>
                    <div class="d2">{{ $t("giorni[1]") }}</div>
                    <div class="d3">{{ $t("giorni[2]") }}</div>
                    <div class="d4">{{ $t("giorni[3]") }}</div>
                    <div class="d5">{{ $t("giorni[4]") }}</div>
                    <div class="d6">{{ $t("giorni[5]") }}</div>
                    <div class="d7">{{ $t("giorni[6]") }}</div>
                </div>
                <div v-if="loading" class="loading mx-3 w-100">
                    <video autoplay loop muted playsinline width="100vw">
                        <source
                            src="../assets/easyload-xl.webm"
                            type="video/webm"
                        />
                    </video>
                </div>
                <div v-else>
                    <div class="days" v-if="sch">
                        <date-picker-day
                            :data-cypress="'day-' + index"
                            v-for="(day, index) in daysInMonth"
                            :key="index"
                            :position="index"
                            @clicked="openPicker(day)"
                            :day="day"
                            :sch="sch"
                            :reservations="reservations"
                            :exceptions="exceptions"
                        />
                    </div>
                </div>
            </div>
            <div v-else>
                <div
                    class="position-sticky top-0 start-0 z-1000 bg-white text-center"
                    v-if="
                        selectedRange &&
                            range &&
                            servizio.resource_grid &&
                            !servizio.visuale_mappa
                    "
                >
                    <p>{{ $t("range.label") }}</p>
                    <MultiSlider
                        v-if="range && date && servizio.resource_grid"
                        class="z-100"
                        :date="selected"
                        :min="range.start"
                        :max="range.end"
                        :step="durata * 1000"
                        :ruler="false"
                        :label="false"
                        :minValue="range.start"
                        :maxValue="range.end"
                        @input="UpdateValues"
                    />
                </div>
                <transition
                    name="fade"
                    v-if="servizio.resource_grid && !servizio.visuale_mappa"
                >
                    <resource-grid
                        :date="selected"
                        :loading="loading"
                        :risorse="daySchedule"
                        :range="selectedRange"
                    />
                </transition>
                <transition name="fade" v-else>
                    <time-picker
                        v-touch:swipe="swipeHandler"
                        :date="selected"
                        :schedule="daySchedule"
                        :reservations="reservations"
                        :loading="loading"
                        :visualizzaMappa="!!servizio.visuale_mappa"
                    />
                </transition>
            </div>

            <!-- dopo fix aggiungere  v-touch:swipe="swipeHandler" a time-picker -->
        </div>
    </div>
</template>

<script>
import TimePicker from "../components/TimePicker";
import CalendarHeader from "../components/CalendarHeader";
import DatePickerDay from "../components/_EDatePickerDay";
import ResourceGrid from "../components/ResourceGrid/ResourceGrid";
import MultiSlider from "../components/inputs/MultiSlider";
import Message from "../components/layout/Message.vue";

import { generateCalendar } from "../mixins/generateCalendar";
import { movesCalendar } from "../mixins/movesCalendar";
import { mapState } from "vuex";

/**
 * @vuese
 * Calendario per la selezione dell'orario
 */
export default {
    name: "EDatePicker",
    mixins: [generateCalendar, movesCalendar],
    components: {
        TimePicker,
        CalendarHeader,
        DatePickerDay,
        ResourceGrid,
        MultiSlider,
        Message
    },
    data() {
        return {
            today: new Date(
                new Date(
                    new Date(new Date().setHours(0)).setMinutes(0)
                ).setSeconds(0)
            ),
            selected: new Date(
                new Date(
                    new Date(new Date().setHours(0)).setMinutes(0)
                ).setSeconds(0)
            ),
            picker: false,
            swipeClass: false,
            selectedRange: {
                start: null,
                end: null
            }
        };
    },
    props: {
        /**
         * @vuese
         * Schedule della giornata
         */
        daySchedule: {
            required: false
        },
        /**
         * @vuese
         * Mostra loading spinner
         */
        loading: {
            tyep: Boolean,
            required: true
        }
    },
    watch: {
        selected(value) {
            this.date = value;
            this.selectedRange = {
                start: null,
                end: null
            };
        },
        range() {
            this.selectedRange = {
                start:
                    this.selectedRange.start == null
                        ? this.range.start
                        : new Date(
                              this.selectedRange.start.setDate(
                                  this.date.getDate()
                              )
                          ),
                end:
                    this.selectedRange.end == null
                        ? this.range.end
                        : new Date(
                              this.selectedRange.end.setDate(
                                  this.date.getDate()
                              )
                          )
            };
        },
        picker() {
            this.$emit("changed", this.picker);
        }
    },
    computed: {
        ...mapState({
            servizio: state => state.Entry.servizio,
            durata: state => state.Entry.durata,
            schedule: state => state.Utils.monthSchedule,
            color: state => state.Ente.colors.sfondo[0],
            range: state => state.Utils.range
        }),
        differentTimezone() {
            return (
                Intl.DateTimeFormat().resolvedOptions().timeZone !=
                process.env.VUE_APP_TIMEZONE
            );
        },
        /**
         * @vuese
         * Booleano per capire se spostare il calendario di un giorno o di un mese
         */
        isMese() {
            return !this.picker;
        },
        /**
         * @vuese
         * Scheduele del servizio
         */
        sch() {
            return this.schedule.schedule;
        },
        /**
         * @vuese
         * eccezioni alla schedule
         */
        exceptions() {
            return this.schedule.schedule.exceptions;
        },
        /**
         * @vuese
         * Prenotazioni
         */
        reservations() {
            return this.schedule.schedule.reservations || null;
        },
        /**
         * @vuese
         * Primo giorno con disponibilità
         */
        primaDisp() {
            if (this.sch.prima_disp) {
                return {
                    day: new Date(this.sch.prima_disp.replace(/-/g, "/")),
                    string: new Date(
                        this.sch.prima_disp.replace(/-/g, "/")
                    ).toLocaleDateString(this.lang, {
                        weekday: "long",
                        month: "long",
                        day: "numeric"
                    })
                };
            }
            return false;
        }
    },
    mounted() {
        this.invalidPrimaDisp();
    },
    methods: {
        /**
         * @vuese
         * Se la prima disponibilità è scaduta manda una richiesta al server per ricaricarla
         */
        invalidPrimaDisp() {
            if (
                this.primaDisp &&
                this.primaDisp.day - this.durata * 1000 < new Date() &&
                this.$route.params.servizio &&
                this.$route.params.area
            ) {
                this.$http.get(
                    `/api/prima_disponibilita/${this.$route.params.servizio}/${this.$route.params.area}`
                );
            }
        },
        /**
         * @vuese
         * Aggiorna i valori dei slider
         */
        UpdateValues(e) {
            this.selectedRange.start = new Date(e.minValue);
            this.selectedRange.end = new Date(e.maxValue);
        },
        /**
         * @vuese
         * Selezione il giorno e muove il calendario di un mese
         * @arg day Date
         */
        moveToMonth(day) {
            this.selected = day;
            this.$emit("monthChanged", this.selected);
        },
        /**
         * @vuese
         * Gestisce il movimento del calendrio (mese prec o succ)
         * con lo swipe
         * @arg direction left | rigth
         */
        swipeHandler(direction) {
            if (!this.loading) {
                if (direction == "left") {
                    let payload = this.next();
                    this.updateSelected(payload);
                } else if (direction == "right") {
                    let payload = this.prev();
                    this.updateSelected(payload);
                }
            }
        },
        /**
         * @vuese
         * ritorna indice del giorno della settimana
         * @arg date Sunday|Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|
         */
        setDate(date) {
            const days = [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday"
            ];
            let dates = days.indexOf(date);
            return dates;
        },
        /**
         * @vuese
         * Seleziona la data
         * @arg date Date
         */
        selectDate(date) {
            this.selected = new Date(date.day.getTime());
        },
        /**
         * @vuese
         * Apre time picker
         */
        openPicker(date) {
            date.day.setHours(23);
            this.selected = new Date(date.day.getTime());
            this.picker = true;
        },
        /**
         * @vuese
         * Aggiorna mese selezionato, chiamato da Calendar Header
         * @arg payload : {selected : date ; type : month | day }
         */
        updateSelected(paylod) {
            let { selected, type } = paylod;
            this.selected = selected;
            if (type == "month") {
                this.$emit("monthChanged", selected);
            }
        },
        /**
         * @vuese
         * Formatta la data come stringa Y-m-d
         * @arg day Date
         */
        formatDay(day) {
            let copy = new Date(day.getTime());
            let arr = copy.toISOString().split("T")[0];
            return arr;
        }
    }
};
</script>

<style scoped>
/* .active-swipe-left{
  position: relative;
}
.active-swipe-left::after{
  content: ' ';
  height: 100%;
  width: 10%;
  position: absolute;
  box-shadow:5px 5px 15px 5px rgba(0, 0, 0, 0.158) ;
  opacity: .7;
  border-radius: 0 70%  70% 0;
  top: 0;
  left : 0;
} */
.active-swipe-rigth {
    position: relative;
}

.active-swipe-rigth::after {
    content: " ";
    height: 100%;
    width: 10%;
    position: absolute;
    /* background-color: #eee; */
    box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.158);
    opacity: 0.7;
    border-radius: 0 70% 70% 0;
    top: 0;
    left: 0;
}

.fade-enter-active {
    transition: opacity 0.4s;
}

.fade-enter {
    opacity: 0;
}

.fade-leave-to {
    opacity: 100%;
}

.fade-leave-active {
    transition: none;
}

.calendar {
    width: 100%;
    height: 100%;
}

.picker {
    padding: 1rem;
    width: 100%;
    height: 100%;
    background-color: var(--calendar-bg);
    border-bottom-right-radius: var(--form-border-radius);
    border-bottom-left-radius: var(--form-border-radius);
}

.weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.d1 {
    grid-area: 1 / 1 / 2 / 2;
}

.d2 {
    grid-area: 1 / 2 / 2 / 3;
}

.d3 {
    grid-area: 1 / 3 / 2 / 4;
}

.d4 {
    grid-area: 1 / 4 / 2 / 5;
}

.d5 {
    grid-area: 1 / 5 / 2 / 6;
}

.d6 {
    grid-area: 1 / 6 / 2 / 7;
}

.d7 {
    grid-area: 1 / 7 / 2 / 8;
}

.weekdays div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.days {
    margin: 20px 7px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 25px;
}

/* .days div:hover:not(.today) {
  background-color: #0066cc;
  border: 0.2rem solid #777;
} */
</style>
