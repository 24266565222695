<template>
    <div
        class="d-flex flex-column w-100"
        data-tour-step="9"
        data-cypress="time-picker"
        v-if="schedule"
    >
      <div class="number_of_reservation_selector" v-if="servizio.multi_prenotazione != 0">
        <label for="#person-mumber">
                {{$t('multi_prenotazione.domanda')}}

        </label>
        <select class="time-pill ml-2" name="person-mumber" id="person-mumber" @change="callSetReservationsNumber()"
          v-model="reservationsNumber">
          <option v-for="n in numeroRisorse" :key="n">{{ n }}</option>
        </select>
      </div>

        <div
            v-if="schedule && isEmpty(availableTimes)"
            class="w-100 d-flex justify-content-center align-items-center mt-5"
        >
            {{
                $t("form.calendario.nessun_orario") ==
                "form.calendario.nessun_orario"
                    ? "Non è più possibile prenotare in questa giornata, il servizio è chiuso"
                    : $t("form.calendario.nessun_orario")
            }}
        </div>
        <loading-spinner
            class="loading"
            v-if="loading || !schedule"
            showText
            tabindex="0"
        />
        <template v-else>
            <label for="durata" v-if="!durata" class="mx-auto m-5">
                {{ $t("form.calendario.time_picker.seleziona_durata") }}
            </label>

            <template v-else>
                <div
                    v-if="
                        sch[formatDay(date)] == undefined ||
                        sch[formatDay(date)][0] == '00:00-24:00'
                    "
                    class="d-flex align-items-center justify-content-center mx-auto"
                >
                    <p class="mt-5">{{ $t("form.calendario.chiusura") }}</p>
                </div>
                <template
                    v-else-if="autoSelectDate"
                    class="d-flex flex-column justify-content-center align-items-center p-md-5 p-2"
                >
                    <div
                        :aria-labelledby="
                            times.filter(
                                (e) => e != undefined && e.booked == false
                            )[0]
                        "
                        tabindex="0"
                        class="chip text-white disponibile m-5 w-100"
                        :style="{
                            backgroundColor: '#666',
                        }"
                        v-tooltip="'clicca per prenotare'"
                        @click="
                            selectDate(
                                times.filter(
                                    (e) => e != undefined && e.booked == false
                                )[0]
                            )
                        "
                        @keyup.enter="
                            selectDate(
                                times.filter(
                                    (e) => e != undefined && e.booked == false
                                )[0]
                            )
                        "
                    >
                        {{
                            times
                                .filter(
                                    (e) => e != undefined && e.booked == false
                                )[0]
                                .date.toLocaleTimeString("it-IT")
                                .replace(/(.*)\D\d+/, "$1")
                        }}
                        <template
                            :id="
                                times.filter(
                                    (e) => e != undefined && e.booked == false
                                )[0]
                            "
                            v-if="
                                configs &&
                                configs.griglia &&
                                configs.griglia.mostra_ora_fine &&
                                configs.griglia.mostra_ora_fine != 0
                            "
                        >
                            -
                            {{
                                endTime(
                                    times.filter(
                                        (e) =>
                                            e != undefined && e.booked == false
                                    )[0].date
                                )
                            }}
                        </template>
                    </div>
                </template>
                <div
                    v-else-if="times"
                    aria-label="time select"
                    class="time-picker d-flex justify-content-center align-items-center flex-wrap p-5"
                >
                    <div
                        v-for="(time, i) in times"
                        :key="i"
                        :class="[
                            times.filter((e) => e != null).length != 0
                                ? ''
                                : 'd-none',
                        ]"
                        style="
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                        "
                    >
                        <div
                            tabindex="0"
                            v-if="time && time.date"
                            class="chip text-white"
                            :style="{
                                backgroundColor:
                                    time.booked || !time.available
                                        ? '#ccc'
                                        : '#666',
                            }"
                            :class="{
                                occupato: time.booked,
                                'not-available': !time.available,
                                'not-available':
                                    !prenotazioniContemporanee && time.reserved,
                                disponibile: !time.booked && time.available,
                                reserved: time.reserved,
                            }"
                            @keyup.enter="selectDate(time)"
                            @click="selectDate(time)"
                            :title="
                                !prenotazioniContemporanee && time.reserved
                                    ? $t('time_picker.prenotazione_in_corso')
                                    : time.booked
                                    ? $t('time_picker.occupied')
                                    : $t('time_picker.book')
                            "
                            v-tooltip="
                                !prenotazioniContemporanee && time.reserved
                                    ? $t('time_picker.prenotazione_in_corso')
                                    : time.booked
                                    ? $t('time_picker.occupied')
                                    : $t('time_picker.book')
                            "
                            :aria-label="
                                time.date
                                    .toLocaleTimeString('it-IT')
                                    .replace(/(.*)\D\d+/, '$1') +
                                (!prenotazioniContemporanee && time.reserved
                                    ? $t('time_picker.prenotazione_in_corso')
                                    : time.booked
                                    ? $t('time_picker.occupied')
                                    : $t('time_picker.book'))
                            "
                        >
                            <span>
                                {{
                                    time.date
                                        .toLocaleTimeString("it-IT")
                                        .replace(/(.*)\D\d+/, "$1")
                                }}
                                <template
                                    v-if="
                                        configs &&
                                        configs.griglia &&
                                        configs.griglia.mostra_ora_fine &&
                                        configs.griglia.mostra_ora_fine != 0
                                    "
                                >
                                    -
                                    {{
                                        time.end
                                            .toLocaleTimeString("it-IT")
                                            .replace(/(.*)\D\d+/, "$1")
                                    }}</template
                                >
                            </span>

                            <template
                                v-if="
                                    configs &&
                                    configs.portale_pubblico &&
                                    configs.portale_pubblico
                                        .mostra_numero_risorse_libere &&
                                    configs.portale_pubblico
                                        .mostra_numero_risorse_libere != 0
                                "
                            >
                                <span
                                    class="text-small"
                                    style="font-size: 12px"
                                >
                                    {{
                                        $t("numero_risorse", {
                                            disponibili: time.show_disponibili,
                                            totale_risorse: time.show_su,
                                        })
                                    }}
                                </span>
                            </template>
                        </div>
                    </div>
                </div>

                <div
                    v-else
                    class="d-flex align-items-center justify-content-center mx-auto"
                >
                    <p class="mt-5">{{ $t("form.calendario.chiusura") }}</p>
                </div>
                <modal
                    v-if="showModaleUpdateStart"
                    @close="resetModaleUpdateStart"
                >
                    <template slot="body">
                        <p>
                            {{ $t("cambio_inizio.title") }}
                        </p>
                        <div class="row mt-2">
                            <div class="col-12 col-md-5">
                                <div
                                    class="d-flex flex-column align-items-center"
                                >
                                    <span class="font-weight-bold"
                                        >{{ $t("cambio_inizio.from") }}
                                    </span>
                                    <div
                                        class="chip text-white p-3"
                                        style="
                                            background-color: #b11616;
                                            line-height: 16px;
                                        "
                                    >
                                        {{
                                            dataProvvisoria.original
                                                .toLocaleTimeString("it-IT")
                                                .replace(/(.*)\D\d+/, "$1")
                                        }}
                                        <template
                                            v-if="
                                                configs &&
                                                configs.griglia &&
                                                configs.griglia
                                                    .mostra_ora_fine &&
                                                configs.griglia
                                                    .mostra_ora_fine != 0
                                            "
                                        >
                                            -
                                            {{
                                                dataProvvisoria.end
                                                    .toLocaleTimeString("it-IT")
                                                    .replace(/(.*)\D\d+/, "$1")
                                            }}
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <span
                                class="p-2 col-2 d-none d-md-flex justify-content-center align-items-center"
                                style="align-self: flex-end; margin-bottom: 5px"
                            >
                                <i class="fas fa-arrow-right"></i>
                            </span>
                            <span
                                class="p-2 col-12 d-md-none d-flex justify-content-center"
                                style="align-self: flex-end; margin-bottom: 5px"
                            >
                                <i class="fas fa-arrow-down"></i>
                            </span>
                            <div class="col-12 col-md-5">
                                <div
                                    class="d-flex flex-column align-items-center"
                                >
                                    <span class="font-weight-bold"
                                        >{{ $t("cambio_inizio.to") }}
                                    </span>
                                    <div
                                        class="chip text-white p-3"
                                        style="
                                            background-color: #056107;
                                            line-height: 16px;
                                        "
                                    >
                                        {{
                                            dataProvvisoria.date
                                                .toLocaleTimeString("it-IT")
                                                .replace(/(.*)\D\d+/, "$1")
                                        }}
                                        <template
                                            v-if="
                                                configs &&
                                                configs.griglia &&
                                                configs.griglia
                                                    .mostra_ora_fine &&
                                                configs.griglia
                                                    .mostra_ora_fine != 0
                                            "
                                        >
                                            -
                                            {{
                                                dataProvvisoria.end
                                                    .toLocaleTimeString("it-IT")
                                                    .replace(/(.*)\D\d+/, "$1")
                                            }}
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template slot="footer">
                        <e-button @click="selectDate(dataProvvisoria)">{{
                            $t("buttons.confirm")
                        }}</e-button>
                        <e-button
                            @click="resetModaleUpdateStart"
                            :danger="true"
                            >{{ $t("buttons.annulla") }}</e-button
                        >
                    </template>
                </modal>
            </template>
        </template>
    </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { generateTimes } from "../mixins/generateTimes";
import { timeUtils } from "../mixins/timeUtils";
import { showsDurate } from "../mixins/showsDurate";
export default {
    name: "TimePicker",
    mixins: [ timeUtils, generateTimes, showsDurate],
    props: {
        date: {
            type: Date,
            required: true,
        },
        schedule: {
            type: Object,
        },
        loading: {
            required: true,
        },
        reservations: {
            type: Object,
        },
        visualizzaMappa: {
            type: Boolean,
        },
    },
    data() {
        return {
            showModaleUpdateStart: false,
            dataProvvisoria: null,
        };
    },

    mounted() {
        this.setTimePicker(this.date);
        this.callSetReservationsNumber()

        if (this.showAutoSelected == false && this.autoSelectDate) {
            this.selectDate(
                this.times.filter((e) => e != undefined && e.booked == false)[0]
            );
        }
    },
    beforeDestroy() {
        this.setTimePicker(false);
    },
    watch: {
        date(value) {
            this.setTimePicker(value);
        },
        schedule() {
            if (this.autoSelectDate) {
                this.selectDate(
                    this.times.filter(
                        (e) => e != undefined && e.booked == false
                    )[0]
                );
            }
        },
    },

    computed: {
        reservationsNumber: {
        get() {
            return this.selectedReservationNumber;
        },
        set(value) {
            this.setReservationsNumber(value);
        }
    },
        ...mapState({
            durata: (state) => state.Entry.durata,
            servizio: (state) => state.Entry.servizio,
            area: (state) => state.Entry.area,
            configs: (state) => state.Ente.configs,
            autoSelectDate: (state) => state.Entry.autoSelectDate,
            numeroRisorse: state => state.Entry.servizio.numero_risorse,
            selectedReservationNumber : state => +state.Entry.numberOfReservations
        }),
        prenotazioniInCorso() {
            return (
                this.configs.general.prenotazione_in_corso &&
                this.configs.general.prenotazione_in_corso != 0
            );
        },
        prenotazioniContemporanee() {
            return (
                this.configs.general.prenotazioni_contemporanee &&
                this.configs.general.prenotazioni_contemporanee == 1
            );
        },
        showAutoSelected() {
            return (
                process.env.VUE_APP_SHOW_AUTOSELECTED ||
                this.servizio.servizio_breve
            );
        },
        sch() {
            return this.schedule && this.schedule.schedule
                ? this.schedule.schedule
                : null;
        },
        oggi: () => new Date(),
        nextDays() {
            let copy = new Date(this.date.getTime());
            return Object.keys(this.sch)[copy.getDay() - 1];
        },

        orari() {
            if (this.sch && this.sch[this.formatDay(this.date)]) {
                return Array.from(this.sch[this.formatDay(this.date)]);
            }
            return null;
        },
        durataSecondi() {
            if (this.servizio.servizio_breve != 0) {
                return "max";
            }
            if (this.durata === "max") {
                return "max";
            }
            return this.durata;
        },
    },

    methods: {
        callSetReservationsNumber() {
         this.setReservationsNumber(this.reservationsNumber);
        },

        endTime(time) {
            const copy = new Date(time.getTime());
            return new Date(
                copy.setSeconds(copy.getSeconds() + this.durataSecondi)
            )
                .toLocaleTimeString("it-IT")
                .replace(/(.*)\D\d+/, "$1");
        },
        ...mapMutations({
            setDate: "Entry/SET_DATE",
            setTimePicker: "Utils/SET_TIME_PICKER",
            setSelectedMonth: "Utils/SET_SELECTED_MONTH",
            setDurata: "Entry/SET_DURATA",
            setReservationsNumber: "Entry/SET_RESERVATIONS_NUMBER"
        }),
        resetModaleUpdateStart() {
            this.showModaleUpdateStart = false;
            this.dataProvvisoria = null;
            this.populateSelect();
        },
        selectDate(time) {
            if (time === undefined) {
                this.setError({
                    message: "server.errors.max_capacity",
                });
                return;
            }
            const slot = { ...time };
            const differenzaInMinutiOraTimeStart = Math.floor(
                Math.abs(slot.date - new Date()) / 1000 / 60
            );
            if (this.servizio.servizio_breve != 0) {
                slot.inizio = this.times.filter(
                    (e) => e != undefined && e.booked == false
                )[0].date;
                if (new Date() > slot.inizio) slot.inizio = new Date();
                this.setDate(slot);
                this.setDurata(this.durataSecondi);
                this.$router.push({
                    name: this.$routes.SERVIZI_BREVI.DATIUTENTE,
                });
                return;
            }

            if (
                this.prenotazioniInCorso &&
                !this.dataProvvisoria &&
                slot.date < new Date() &&
                differenzaInMinutiOraTimeStart > 1
            ) {
                this.showModaleUpdateStart = true;
                const provvisorio = { ...time };
                provvisorio.date = new Date();
                provvisorio.original = time.date;
                this.dataProvvisoria = provvisorio;
                return;
            }
            this.setDate(slot);
            if (this.durataSecondi > (slot.end - slot.date) / 1000)
                this.setDurata((slot.end - slot.date) / 1000);
            if (this.durata == "max") {
                const durata =
                    (slot.end.getTime() - slot.date.getTime()) / 1000;
                this.setDurata(durata);
            }
            if (this.visualizzaMappa) {
                this.$router.push({ name: this.$routes.PRENOTA.MAPPA });
            } else {
                this.$router.push({ name: this.$routes.PRENOTA.DATIUTENTE });
            }
        },
    },
};
</script>

<style scoped>
.number_of_reservation_selector{
    display : flex;
    justify-content : center;
    align-items : center;
    padding : 10px;
    border-bottom : 1px solid black;
}
.number_of_reservation_selector label {
    margin : 0;
}
.time-picker {
    overflow-y: scroll;
    width: 100%;
    min-height: 100px;
    height: 100%;
}

/* .picker::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.picker::-webkit-scrollbar {
  width: 15px;
  background-color: #f5f5f5;
}

.picker::-webkit-scrollbar-thumb {
  background-color: #0066cc;
} */

/* .hour {
  width: 30%;
  height: 100%;
  padding: 2rem 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 2px solid #eee;
} */
.disponibile,
.occupato,
.not-available {
    margin: 0.3rem;
    background-color: white;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.disponibile {
    cursor: pointer;
}

.occupato,
.not-available {
    pointer-events: none;
}

.not-available::after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 120px;
    height: 1px;
    font-size: 1rem;
    background-color: #666;
    opacity: 0.7;
    transform: rotate(335deg);
}

.occupato::after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 120px;
    height: 1px;
    font-size: 1rem;
    background-color: #666;
    opacity: 0.7;
    transform: rotate(335deg);
}

.chip {
    display: flex;
    flex-direction: column;
    padding: 0 25px;
    height: 50px;
    font-size: 16px;
    border-radius: 8px;
    /* background-color: #9bff8e; */
    user-select: none;
    border: 1px solid #666;
}

.reserved {
    background-color: rgb(197, 197, 75) !important;
    color: black !important;
}
</style>
