<template>
    <div class="month" id="calendarHeader">
        <span
            tabindex="0"
            @click.prevent="prev"
            @keyup.enter.prevent="prev"
            aria-label="indietro"
            v-tooltip="
                prevDisabled
                    ? $t('calendar-header.precedente')
                    : $t('buttons.back')
            "
            :class="{ disabled: prevDisabled, disabled: isLoading }"
            :style="prevDisabled ? 'cursor : no-drop;' : 'cursor: pointer'"
        >
            <i class="fas fa-angle-left prev"></i>
        </span>
        <div
            class="date"
            tabindex="0"
            @click="$emit('reset')"
            @keyup.enter="$emit('reset')"
            :class="{ disabled: isLoading }"
        >
            <h1 style="color:'#6c6c6c'">
                <span v-if="isMese">{{ dateString }}</span>
                <span v-else>{{ dateString }}</span>
            </h1>
        </div>
        <span
            tabindex="0"
            @click.prevent="next"
            @keyup.enter.prevent="next"
            aria-label="avanti"
            :class="{
                disabled: nextDisabled || nextBlocked,
                disabled: isLoading
            }"
            v-tooltip="
                nextDisabled || nextBlocked
                    ? $t('calendar-header.successivo')
                    : $t('buttons.next')
            "
            :style="
                nextDisabled || nextBlocked
                    ? 'cursor : no-drop;  pointer-events: none;'
                    : 'cursor: pointer'
            "
        >
            <i class="fas fa-angle-right next"></i>
        </span>
    </div>
</template>

<script>
import { movesCalendar } from "../mixins/movesCalendar";
export default {
    name: "CalendarHeader",
    mixins: [movesCalendar],
    props: {
        isMese: {
            type: Boolean,
            default: true
        },
        selectedDate: {
            required: true
        },
        schedule: {
            required: true
        },
        isLoading: {
            tyep: Boolean,
            required: true
        }
    },
    watch: {
        isMese() {
            this.computeDateString();
        },
        selectedDate() {
            this.computeDateString();
        },
        selected() {
            this.computeDateString();
        }
    },
    mounted() {
        this.computeDateString();
    },
    methods: {
        computeDateString() {
            this.selected = this.selectedDate;
            const copy = new Date(this.selected.getTime());

            if (this.isMese) {
                this.dateString = copy.toLocaleDateString(this.lang, {
                    month: "long"
                });
                return;
            }
            this.dateString = copy.toLocaleDateString(this.lang, {
                weekday: "long",
                month: "long",
                day: "numeric"
            });
            return;
        }
    },
    computed: {
        prevDisabled() {
            const copy = new Date(this.selectedDate.getTime());
            if (!this.isMese) {
                const newSelected = new Date(copy.setDate(copy.getDate()));
                if (new Date() > newSelected) {
                    return true;
                }
            } else {
                const newSelected = new Date(
                    new Date(copy.setMonth(copy.getMonth() - 1)).setDate(
                        new Date().getDate() + 1
                    )
                );
                if (newSelected < new Date()) {
                    return true;
                }
            }
            return false;
        },
        nextBlocked() {
            const copy = new Date(this.selected);
            if (this.isMese) {
                const newSelected = new Date(
                    new Date(copy.setMonth(copy.getMonth() + 1)).setDate(1)
                );
                if (
                    this.schedule.validità != null &&
                    new Date(this.schedule.validità) < newSelected
                ) {
                    return true;
                }
            }
            return false;
        }
    }
};
</script>

<style scoped>
.month {
    cursor: pointer;
    width: 100%;
    height: 50px;
    background-color: var(--calendar-bg);
    border-bottom: 1px solid black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    text-align: center;
    color: black;
}

.month i {
    font-size: 1.7rem;

    cursor: pointer;
}
.date {
    display: flex;
    justify-content: center;
    align-items: center;
}

.date h1 span {
    font-size: 1.1rem;
    font-weight: 300;
    text-transform: capitalize;
    letter-spacing: 0.1rem;
}
.date h1 {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
}
.date p {
    font-size: 1rem;
    margin: 0;
    margin-left: 10px;
}
.disabled {
    cursor: none;
    color: #ccc;
    pointer-events: none;
}
</style>
