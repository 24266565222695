var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.orientation === 'horizontal' ? 'd-flex' : ''},_vm._l((_vm.filteredTimes),function(time,i){return _c('div',{key:i},[(time && time.date)?_c('div',{ref:time.now,refInFor:true,staticClass:"border",class:[
                {
                    occupato: time.booked,
                    past: time.past,
                    disponibile: !time.booked && time.available,
                    reserved: time.reserved,
                    booked: time.booked
                },
                _vm.orientation === 'horizontal' ? 'cell-horizontal' : 'cell'
            ],attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.selectDate(time)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.selectDate(time)}}},[_vm._v(" "+_vm._s(time.date .toLocaleTimeString("it-IT") .replace(/(.*)\D\d+/, "$1"))+" "),(
                    _vm.configs &&
                        _vm.configs.griglia &&
                        _vm.configs.griglia.mostra_ora_fine &&
                        _vm.configs.griglia.mostra_ora_fine != 0
                )?[_vm._v(" - "+_vm._s(time.end .toLocaleTimeString("it-IT") .replace(/(.*)\D\d+/, "$1")))]:_vm._e()],2):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }