import { mapState } from "vuex";
import AvailableTimes from "./AvailableTimes";
export const generateTimes = {
    /***
     * se uno slot è minore alla risoluzione della griglia lo mostriamo?
     */
    methods: {
        getStart(p) {
            if (Array.isArray(p)) {
                p = p[0];
            }
            const hour = p.split("-")[0].split(":")[0];
            const minutes = p.split("-")[0].split(":")[1];
            return new Date(
                new Date(new Date(this.date.setHours(hour)).setMinutes(minutes))
            );
        },
        getEnd(p) {
            if (Array.isArray(p)) {
                p = p[0];
            }
            const hour = p.split("-")[1].split(":")[0];
            const minutes = p.split("-")[1].split(":")[1];
            return new Date(
                new Date(new Date(this.date.setHours(hour)).setMinutes(minutes))
            );
        },
        booked(time, durata = null) {
            if (durata === null) durata = this.durata;
            if (this.periods) {
                return (
                    this.periods
                        .map(e => {
                            const startEcc = e.start;
                            const endEcc = e.end;

                            const startSlot = time;
                            const endSlot = new Date(
                                time.getTime() + durata * 1000
                            );
                            if (startEcc < endSlot && startSlot < endEcc) {
                                return true;
                            }
                        })
                        .filter(e => e).length > 0
                );
            }
            return false;
        },
        reserved(time) {

            return this.mappedReservations.map(e => {
                const ecc = {
                    start: e.start,
                    end: e.end
                };
                const slot = {
                    start: time,
                    end: new Date(time.getTime() + this.durata * 1000)
                }

                if (ecc.start < slot.end && slot.start < ecc.end) {
                    return true;
                }
                return false;
            }).filter(e => e).length > 0;
        },
        exceptionsStart(ex) {
            const hour = ex.split("-")[0].split(":")[0];
            const minutes = ex.split("-")[0].split(":")[1];
            return new Date(
                new Date(new Date(this.date.setHours(hour)).setMinutes(minutes))
            );
        },
        exceptionsEnd(ex) {
            const hour = ex.split("-")[1].split(":")[0];
            const minutes = ex.split("-")[1].split(":")[1];
            return new Date(
                new Date(new Date(this.date.setHours(hour)).setMinutes(minutes))
            );
        },
    },
    computed: {
        ...mapState({
            durata: state => state.Entry.durata,
            servizio: state => state.Entry.servizio,
            configs: state => state.Ente.configs,
            reservationsNumber: state => state.Entry.reservationsNumber,
            number_of_reservations: state => +state.Entry.numberOfReservations

        }),
        exceptions() {
            if (this.schedule.exceptions[this.formatDay(this.date)]) {
                return this.schedule.exceptions[
                    this.formatDay(this.date)
                ].map((e) => {
                    const startHour = e.split("-")[0].split(":")[0];
                    const startMinutes = e.split("-")[0].split(":")[1];
                    const endHour = e.split("-")[1].split(":")[0];
                    const endMinutes = e.split("-")[1].split(":")[1];
                    const copy = new Date(this.date.getTime());
                    return {
                        start: new Date(
                            new Date(copy.setHours(startHour)).setMinutes(startMinutes)
                        ),
                        end: new Date(
                            new Date(copy.setHours(endHour)).setMinutes(endMinutes)
                        ),
                    };
                });
            } else return null;
        },
        periods() {
            let date = this.formatDay(this.date);

            if (this.schedule.periods[date]) {
                return this.schedule.periods[date].map(slot => {
                    let newDate =
                        date.split("-")[1] +
                        "/" +
                        date.split("-")[2] +
                        "/" +
                        date.split("-")[0];
                    return {
                        start: new Date(newDate + " " + slot.split("-")[0]),
                        end: new Date(newDate + " " + slot.split("-")[1])
                    };
                });
            }
        },
        mappedReservations() {
            let date = this.formatDay(this.date);

            if (this.reservations && this.reservations[date]) {
                return this.reservations[date].map(slot => {
                    let newDate =
                        date.split("-")[1] +
                        "/" +
                        date.split("-")[2] +
                        "/" +
                        date.split("-")[0];
                    return {
                        start: new Date(newDate + " " + slot.split("-")[0]),
                        end: new Date(newDate + " " + slot.split("-")[1])
                    };
                });
            }
            return [];
        },
        times() {
            let times = this.availableTimes
                .reduce((a, b) => a.concat(b))
                .filter(e => e != null && e != undefined)
                .sort((a, b) => a.date.getTime() - b.date.getTime());

            //condizione per eliminare quelli in corso più vecchi
            if (this.configs.general.prenotazione_in_corso && this.configs.general.prenotazione_in_corso === 'ultima') {
                let inCorso = times.filter(e => e.inCorso == true);
                let nonInCorso = times.filter(e => !e.inCorso)
                times = [inCorso[inCorso.length - 1], ...nonInCorso];
            }
            return times;
        },
        availableTimes() {
            if (!this.orari) return false;
            const times = new AvailableTimes(this.durataSecondi, this.orari, this);
            return times.make();
        }
    }
};
