
export default class AvailableTimes {

    constructor(type, orari, context) {
        this.type = type
        this.availableTimes = [];
        this.orari = orari.filter(e => e);
        //context sono i dati del componente e le sue funzioni da qui si può accedere a tutto quello dichiarato in generateTimes e nel componente che lo implementa
        this.context = context
        this.length = orari.flat().map(e =>
            e
                .split("-")
                .reverse()
                .map(e => {
                    return new Date(
                        new Date(
                            new Date().setHours(e.split(":")[0])
                        ).setMinutes(e.split(":")[1])
                    ).setSeconds(0);
                })
                .reduce((a, c) => Math.ceil((Math.abs(a - c) / 36e5) * 60 * 60))
            //36e5 è la notazione scientifica di 60*60*1000
        );
    }
    make() {
        // al momento non è stato implementato il refactoring per i servizi di tipo resource_grid
        //TODO: implementarlo in modo che la funzione getAvailableTimes possa gestire tutto
        if (this.context.servizio.resource_grid == 0)
            return this.getAvailableTimes();
        else {
            switch (this.type) {
                case 'max':
                    return this.getMaxAvailableTimes();
                default:
                    return this.getNormalAvailableTimes();

            }
        }

    }

    getAvailableTimes() {
        const daySchedule = this.context.schedule.schedule[this.context.formatDay(this.context.date)];
        return [Array.from(Object.keys(daySchedule).map(key => {
            const [inizio_slot, fine_slot] = this.context.timePeriodStringToDate(key)
            let { disponibili, su, reserved } = daySchedule[key];

            const show_disponibili = disponibili;
            const show_su = su;
            disponibili = Math.max(0, disponibili - this.context.number_of_reservations + 1);
            su = Math.max(0, su - this.context.number_of_reservations + 1);

            const inCorso = inizio_slot <= new Date() && fine_slot > new Date();
            if (this.context.servizio.servizio_breve) {
                return {
                    date: inizio_slot,
                    end: fine_slot, booked: false, reserved: false, available: true, inCorso
                }
            }
            if (this.context.configs.general.prenotazione_in_corso && this.context.configs.general.prenotazione_in_corso != 0) {

                if (fine_slot > new Date()) {
                    return {
                        date: inizio_slot,
                        end: fine_slot,
                        booked:
                            fine_slot < new Date() ||
                            disponibili == 0,
                        reserved,
                        inCorso,
                        available: disponibili > 0,
                        totale_risorse: su,
                        disponibili,
                        show_disponibili,
                        show_su
                    };
                }
                return null;
            }
            if (this.context.durata === 'max' && inizio_slot < new Date()) {
                return {
                    date: new Date(), end: fine_slot, booked: fine_slot < new Date() ||
                        disponibili == 0, reserved, available: true, totale_risorse: su, disponibili, inCorso,
                    show_disponibili,
                    show_su

                };
            }

            //caso in cui non si può
            if (inizio_slot > new Date()) {
                return {
                    date: inizio_slot, end: fine_slot, booked: fine_slot < new Date() ||
                        disponibili == 0, reserved, available: true, totale_risorse: su, disponibili, inCorso,
                    show_disponibili,
                    show_su

                };
            }



            return null;
        }))];

    }
    getNormalAvailableTimes() {
        let durata =
            this.context.servizio.risoluzione_griglia_pubblica &&
                this.context.servizio.risoluzione_griglia_pubblica > 0
                ? this.context.servizio.risoluzione_griglia_pubblica
                : this.context.durataSecondi;
        let x = this.length.map((e, i) => {
            let distanza = new Array(Math.floor(e / durata))
                .fill("")
                .map((v, i) => i * durata);
            return distanza.map(e => {
                let d = new Date(this.context.firstTime(i));
                let date = new Date(d.setSeconds(this.context.firstTime(i).getSeconds() + e));
                console.log(date)
                let available = new Date(date.getTime() + this.context.durata * 1000) <= this.context.lastTime(i);
                //capisco se prenotazioni intersecano slot successivi
                if (this.context.exceptions) {
                    const prenotazioni_successive_intersecano = this.context.exceptions
                        .filter(el => {
                            if (this.context.firstTime(i) > el.start) {
                                return false;
                            }
                            return (
                                new Date(
                                    date.getTime() + this.context.durata * 1000
                                ) < el.end &&
                                new Date(
                                    date.getTime() + this.context.durata * 1000
                                ) > el.start
                            );
                        }).length < 0;

                    available = available && !prenotazioni_successive_intersecano;
                }

                //fine dello slot
                const end = new Date(date.getTime() + this.context.durata * 1000);

                //se slot è oltre l'ultimo orario torna null
                if (end > this.context.lastTime(i)) {
                    return null;
                }
                if (this.context.servizio.servizio_breve) {
                    return {
                        date, end, booked: false, reserved: false, available: true
                    }
                }
                //caso in cui si possa prenotare per uno slot già iniziato
                if (this.context.configs.general.prenotazione_in_corso && this.context.configs.general.prenotazione_in_corso != 0) {

                    if (end > new Date()) {
                        return {
                            date,
                            end,
                            booked: end < new Date() || this.context.booked(date),
                            reserved: this.context.reserved(date),
                            inCorso: date <= new Date() && end > new Date(),
                            available
                        };
                    }
                    return null;
                }
                //7,2e+6 (2 ore in secondi) per arrivare a +2 Europa/Roma

                //caso in cui non si può
                if (date > new Date()) {
                    return {
                        date,
                        end,
                        booked: this.context.booked(date),
                        reserved: this.context.reserved(date),
                        inCorso: date <= new Date() && end > new Date(),
                        available
                    };
                }
                return null;

            });
        });

        return x;
    }
    getMaxAvailableTimes() {

        /**
         * in caso di durata max la durata è variabile
         * e va calcolata trammite l'intersezione tra l'orario e le prenotazioni
         */

        // let orari = this.context.orari; //orari del servizio
        if (this.context.formatDay(this.context.date) in this.context.schedule.exceptions) {
            this.orari = this.context.schedule.exceptions[this.context.formatDay(this.context.date)].concat(this.context.schedule.periods[this.context.formatDay(this.context.date)]).filter(e => e); //prenotazioni generali del servizio
        }

        let length = this.orari.map(e =>
            e
                .split("-")
                .reverse()
                .map(e => {
                    return new Date(
                        new Date(
                            new Date().setHours(e.split(":")[0])
                        ).setMinutes(e.split(":")[1])
                    ).setSeconds(0);
                })
                .reduce((a, c) => (Math.ceil(Math.abs(a - c) / 36e5) * 60 * 60))
        );
        //array degli slot orari
        let times = length.map((e, i) => {
            let orario = this.orari[i];
            let start = this.context.getStart(orario);
            let end = this.context.getEnd(orario);
            let exStart = null;
            let exEnd = null;
            let startEcc = null;

            if (this.context.schedule.periods.length > 0) {
                const intersezione = this.context.schedule.periods[
                    this.context.formatDay(this.date)
                ][i];
                exStart = this.context.exceptionsStart(intersezione);
                exEnd = this.context.exceptionsEnd(intersezione);
                startEcc = this.context.exceptionsStart(intersezione);
            }
            let endSlot = end;
            let startSlot = start;
            if (exStart && exEnd) {
                endSlot = exStart > start ? exStart : end;
                startSlot = exEnd > end ? end : exEnd;
            }
            let durata = Math.abs((endSlot - startSlot) / 1000);
            let prenotazione = 0;
            if (startEcc) {
                prenotazione = Math.abs(start - startEcc) / 1000;
            }
            //array con distanze in secondi partendo da start (la distanza è la durata)
            const arrayElements = Math.floor((e - prenotazione) / durata)
            let distanza = new Array(arrayElements ? arrayElements : 0)
                .fill("")
                .map((v, i) => i * durata);
            return distanza.map(() => {
                const date = startSlot;
                let available =
                    new Date(date.getTime() + durata * 1000) <=
                    this.context.lastTime(i, this.orari);
                if (this.context.exceptions) {
                    const prenotazioni_successive_intersecano =
                        this.context.exceptions.filter(el => {
                            if (this.context.firstTime(i, this.orari) > el.start) {
                                return false;
                            }

                            return (
                                new Date(
                                    date.getTime() + durata * 1000
                                ) < el.end &&
                                new Date(
                                    date.getTime() + durata * 1000
                                ) > el.start
                            );
                        }).length < 0;

                    available =
                        available &&
                        !prenotazioni_successive_intersecano;
                }
                if (
                    new Date(date.getTime() + durata * 1000) >
                    this.context.lastTime(i, this.orari)
                ) {
                    return null;
                }
                const end = new Date(date.getTime() + durata * 1000);
                if (this.context.servizio.servizio_breve) {
                    return {
                        date, end, booked: false, reserved: false, available: true
                    }
                }
                if (this.context.configs.general.prenotazione_in_corso && this.context.configs.general.prenotazione_in_corso != 0) {
                    if (end > new Date()) {
                        return {
                            date,
                            end,
                            booked:
                                end < new Date() ||
                                this.context.booked(date, durata),
                            reserved: this.context.reserved(date),
                            inCorso: date <= new Date() && end > new Date(),
                            available
                        };
                    }
                    return null;
                } else if (date > new Date()) {
                    return {
                        date,
                        end,
                        booked: this.context.booked(date, durata),
                        reserved: this.context.reserved(date),
                        inCorso: date <= new Date() && end > new Date(),
                        available
                    };
                }
            });
        });
        return times;

    }
    intersezione(orari, prenotazioniGiornata) {
        // per ogni orario calcolo slot di intersezione
        const results = orari.map(slot => {
            //loop per ogni prenotazione
            return prenotazioniGiornata.map(prenotazione => {
                //se sono uguali ritorno uno dei due
                if (prenotazione === slot) return slot

                const prenotazioneObj = {
                    start: this.context.getStart(prenotazione),
                    end: this.context.getEnd(prenotazione)
                }
                const slotObj = {
                    start: this.context.getStart(slot),
                    end: this.context.getEnd(slot)
                }
                // const intersezione = {
                //     start: new Date(Math.max(prenotazioneObj.start, slotObj.end)),
                //     end: new Date(Math.max(prenotazioneObj.end, slotObj.start))
                // }
                if (prenotazioneObj.start > slotObj.start) {
                    const intersezione = {
                        start: new Date(Math.min(prenotazioneObj.start, slotObj.end)),
                        end: new Date(Math.min(prenotazioneObj.end, slotObj.start))
                    }
                    return this.formatSlot(intersezione);
                }
                const intersezione = {
                    start: new Date(Math.max(prenotazioneObj.end, slotObj.start)),
                    end: new Date(Math.max(prenotazioneObj.start, slotObj.end))
                }
                return this.formatSlot(intersezione);
            })
        })
        //ritorno orari unici
        return [...new Set(results.flat())];
    }

    formatSlot(slot) {
        //return string as hh:mm-hh:mm
        const start = slot.start.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit"
        })
        const end = slot.end.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit"
        })
        return `${start}-${end}`
    }
    roundMinutes(seconds) {
        var timeToReturn = new Date();
        const minutes = seconds / 60
        timeToReturn.setMilliseconds(Math.floor(timeToReturn.getMilliseconds() / 1000) * 1000);
        timeToReturn.setSeconds(Math.floor(timeToReturn.getSeconds() / 60) * 60);
        timeToReturn.setMinutes(Math.floor(timeToReturn.getMinutes() / minutes) * minutes);
        return timeToReturn;

    }
}
