<template>
    <div
        class="calendar position-relative"
        data-tour-step="8"
        data-cypress="calendario"
    >
        <div v-if="isLoading" class="loading">
            <video autoplay loop muted playsinline width="200px">
                <source src="../../assets/easyload-xl.webm" type="video/webm" />
            </video>
        </div>
        <e-date-picker
            v-if="!isLoading"
            :loading="scheduleLoading"
            :daySchedule="schedule"
            @monthChanged="monthChanged"
            @onSelect="selectDate"
            @showDurataModal="showDurataModal = true"
            @changed="isTimePicker = !isTimePicker"
        />
    </div>
</template>

<script>
import EDatePicker from "../../components/EDatePicker";
import { mapMutations, mapState } from "vuex";
import { showsDurate } from "../../mixins/showsDurate";
import { range } from "../../mixins/range";
//@group Form
export default {
    name: "Calendario",
    mixins: [showsDurate, range],
    components: {
        EDatePicker
    },
    data() {
        return {
            schedule: null,
            isLoading: true,
            orari: null,
            closed: true,
            scheduleLoading: false,
            isTimePicker: false
        };
    },
    /**
     * Prima di lasciare la pagina se la navigazione è diretta verso Servizi
     * cancella tutti i dati della entry
     */
    beforeRouteLeave(to, __, next) {
        if (to.name == "servizi") {
            this.setDate(null);
            this.setArea(null);
            this.resetEntry();
        }
        next();
    },

    computed: {
        ...mapState({
            areaObj: state => state.Entry.area,
            servizioObj: state => state.Entry.servizio,
            reservations: state => state.User.reservations,
            user: state => state.User,
            cliente: state => state.Ente.cliente,
            timePicker: state => state.Utils.timePicker,
            selectedDate: state => state.Utils.selectedMonth,
            monthSchedule: state => state.Utils.monthSchedule,
            resourceGrid: state => state.Utils.resourceGrid,
            selectedMonth: state => state.Utils.selectedMonth,
            durataSecondi: state => state.Entry.durata,
            formHeaderLabel: state => state.Utils.formHeaderLabel,
            number_of_reservations: state => state.Entry.numberOfReservations
        }),
        /**
         * ritrona id dell'area prendendolo dallo state se presente o dalla rotta
         */
        area() {
            return this.areaObj != null
                ? this.areaObj.id
                : this.$route.params.area;
        },
        /**
         * ritrona id del servizio prendendolo dallo state se presente o dalla rotta
         */
        servizio() {
            return this.servizioObj != null
                ? this.servizioObj.id
                : this.$route.params.servizio;
        },
        mese() {
            return this.selectedDate
                .split("-")
                .splice(0, 2)
                .join("-");
        }
    },

    watch: {
        durataSelezionata(value) {
            if (
                !this.resourceGrid &&
                (value === this.servizioObj.durata ||
                    this.durataInSelect(value))
            ) {
                this.getSchedule();
            }
        },
        number_of_reservations() {
            this.getSchedule();
        },
        timePicker() {
            if (!this.scheduleLoading) {
                this.getSchedule();
            }
        },
        isTimePicker(value) {
            if (value == false) {
                this.getSchedule();
            }

            if (
                this.formHeaderLabel.number.passo === 2 &&
                this.formHeaderLabel.number.di === 6 &&
                value === true
            ) {
                this.setFormHeaderLabel({
                    name: "form.menu.orario",
                    number: { passo: 3, di: 6 }
                });
            }
        }
    },

    mounted() {
        this.init().then(() => {
            if (
                !this.durata ||
                !this.durataSecondi ||
                !this.durataInSelect(this.durata)
            ) {
                this.populateSelect();
            }
            if (this.timePicker && this.timePicker != false) {
                let arr = this.timePicker
                    .toJSON()
                    .slice(0, 10)
                    .split("-");

                this.setSelectedMonth(arr.join("-"));
            } else {
                this.setSelectedMonth(new Date().toJSON().slice(0, 10));
            }

            this.getSchedule();
        });
    },
    methods: {
        async init() {
            if (!this.areaObj || !this.servizioObj) {
                await this.$http
                    .get(`/api/cliente/servizio/${this.$route.params.servizio}`)
                    .then(res => this.setServizio(res.data));

                await this.$http
                    .get(`/api/cliente/area/${this.$route.params.area}`)
                    .then(res => this.setEntryArea(res.data));
            }
        },
        ...mapMutations({
            setDate: "Entry/SET_DATE",
            setArea: "Ente/POPULATE_AREE",
            setMonthSchedule: "Utils/SET_MONTHSCHEDULE",
            setEntryArea: "Entry/SET_AREA",
            setServizio: "Entry/SET_SERVIZIO",
            resetEntry: "Entry/RESET_ENTRY",
            setSelectedMonth: "Utils/SET_SELECTED_MONTH",
            setFormHeaderLabel: "Utils/SET_FORM_HEADER_LABEL"
        }),
        /**
         * @vuese
         * Chiamato quando il calendario passa alla modalità timepicker
         * richiama anche la schedule
         */
        isTimePickerEvent() {
            this.$emit("time-picker");
            this.isTimePicker = !this.isTimePicker;
            this.getSchedule();
        },
        toogleLegenda() {
            this.closed = !this.closed;
        },

        /**
         * @vuese
         * Chiamato quando il mese selezionato cambia
         * Richiama la schedule
         * @arg data : Date
         */
        monthChanged(data) {
            let copy = new Date(data.getTime());
            this.setSelectedMonth(this.formatDay(copy));
            this.getSchedule();
        },
        /**
         * @vuese
         * Formatta la data come Y-m-d
         * @arg day : Date
         */
        formatDay(day) {
            let copy = new Date(day.getTime());
            var mm = copy.getMonth() + 1; // getMonth() is zero-based
            var dd = copy.getDate();

            return [
                copy.getFullYear(),
                (mm > 9 ? "" : "0") + mm,
                (dd > 9 ? "" : "0") + dd
            ].join("-");
        },
        /**
         * @vuese
         * Recupera chiave primaria del cliente
         */
        getPrimaria() {
            return this.$http
                .get(`api/cliente/${this.cliente.id}/primaria`)
                .then(res => res);
        },
        /**
         * @vuese
         * Chiama api per recuperare la schedule del servizio
         * sia in formato mensile che giornaliero
         */
        getSchedule() {
            return new Promise((resolve, reject) => {
                const durata = this.durataSecondi;

                this.scheduleLoading = true;

                let url = `api/entry/${this.servizio}/schedule/${this.mese}/${this.area}/${durata}`;

                if (this.timePicker && this.isTimePicker) {
                    url = `api/entry/${this.servizio}/schedule/${this.formatDay(
                        this.timePicker
                    )}/${this.area}/${durata}`;
                }
                if (this.resourceGrid) {
                    if (this.isTimePicker) {
                        url = `api/risorsa/${this.servizio}/${
                            this.area
                        }/${this.formatDay(this.timePicker)}/${durata}`;
                    } else {
                        url = `api/entry/${this.servizio}/schedule/${this.mese}/${this.area}/${durata}`;
                    }
                }

                if (this.user.isLogged) {
                    this.getPrimaria().then(res => {
                        if (this.user.loggedJwt[res.data.codice]) {
                            url =
                                url +
                                "?user_primary=" +
                                this.user.loggedJwt[res.data.codice];
                            this.$http
                                .get(url)
                                .then(res => {
                                    if (this.timePicker) {
                                        this.schedule = res.data;
                                        this.isLoading = false;
                                        this.scheduleLoading = false;
                                        resolve();
                                    } else {
                                        this.setMonthSchedule({
                                            schedule: res.data,
                                            month: this.mese,
                                            servizio: this.servizio,
                                            area: this.area
                                        });
                                        this.isLoading = false;
                                        this.scheduleLoading = false;
                                        resolve();
                                    }
                                })
                                .then(() => {
                                    if (this.isTimePicker)
                                        this.calculateRange();
                                })
                                .catch(() => reject());
                        } else {
                            this.$http
                                .get(url)
                                .then(res => {
                                    if (this.timePicker) {
                                        this.schedule = res.data;
                                        this.isLoading = false;
                                        this.scheduleLoading = false;
                                        resolve();
                                    } else {
                                        this.setMonthSchedule({
                                            schedule: res.data,
                                            month: this.mese,
                                            servizio: this.servizio,
                                            area: this.area
                                        });
                                        this.isLoading = false;
                                        this.scheduleLoading = false;
                                        resolve();
                                    }
                                })
                                .then(() => {
                                    if (this.isTimePicker)
                                        this.calculateRange();
                                })

                                .catch(() => reject());
                        }
                    });
                } else {
                    this.$http
                        .get(url)
                        .then(res => {
                            if (this.timePicker) {
                                this.schedule = res.data;
                                this.isLoading = false;
                                this.scheduleLoading = false;
                                resolve();
                            } else {
                                this.setMonthSchedule({
                                    schedule: res.data,
                                    month: this.mese,
                                    servizio: this.servizio
                                });
                                this.isLoading = false;
                                this.scheduleLoading = false;
                                resolve();
                            }
                        })
                        .then(() => {
                            if (this.isTimePicker) this.calculateRange();
                        })
                        .catch(() => reject());
                }
                this.isLoading = false;
            });
        },
        /**
         * @vuese
         * Seleziona la data
         */
        selectDate(val) {
            this.day = val;
        }
    }
};
</script>

<style scoped>
.legenda.closed {
    height: 50px;
}

.legenda div {
    height: 20px;
    display: none;
}

.legenda.closed div {
    /* display: none; */
    display: inline-block;
}

.legenda.closed ul {
    display: none;
}

.legenda {
    border-top: 1px solid #666;
    padding: 0.3rem 1rem;
    display: flex;
    align-items: center;
}

.legenda ul {
    list-style: none;
    white-space: nowrap;
    width: 33%;
}

.legenda ul li {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin: 0.3rem;
}

.legenda ul li div {
    display: inline-block;
    height: 25px;
    width: 25px;
    margin-right: 10px;
}

.calendar {
    height: 100%;
    min-height: 100%;
}

.yellow {
    background-color: yellow;
}

.red {
    background-color: red;
}

.gray {
    background-color: gray;
}

.loading {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 600px) {
    .legenda {
        border: 1px solid #666;
    }
}
</style>
