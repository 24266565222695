
export const generateCalendar = {
    data(){
        return {
            date: new Date(),
        }
    },
    
    computed: {
        lastDay() {
            return new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
          },
        daysInMonth() {
         
            let month = new Array(this.lastDay.getDate())
              .fill("")
              .map(
                (v, i) =>
                  new Date(this.date.getFullYear(), this.date.getMonth(), i + 1)
              )
              .filter((v) => v.getMonth() === this.date.getMonth())
              .map((e) => {
                return {
                  day: e,
                  type: "current",
                };
              });
      
            // calcolo giorni del mese precedente
            let prev = [];
            
            const prevlastDay = new Date(
              this.date.getFullYear(),
              this.date.getMonth(),
              0
            );
              
            let to = month[0].day.getDay() - 1 > 0 ? month[0].day.getDay() - 1 : month[0].day.getDay() - 1 ==  0 ? 0 : 6
            // console.log(month[0].day.getDay() - 1)
            for (let i = to; i > 0; i--) {
              
              prev.push({
                day: new Date(
                  this.date.getFullYear(),
                  this.date.getMonth() - 1,
                  prevlastDay.getDate() - i  + 1
                ),
                type: "prev",
              });
            }
      
            let next = [];
            for (let j = 35 - this.lastDay.getDate() - prev.length ; j > 0 ; j--) {
              next.push({
                day: new Date(
                  this.date.getFullYear(),
                  this.date.getMonth() + 1,
                  j
                ),
                type: "next",
              });
            }
            return [...prev, ...month, ...next.reverse()];
          },
    }
};
