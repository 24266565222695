<template>
    <div>
        <modal v-if="show" @close="$emit('closed')" data-cypress="area-modal">
            <template slot="body">
                <div class="descrition service-description my-3">
                    <div>{{ $t("resource_grid.selected") }}</div>
                    <p>{{ risorsa.risorsa.resource_name }}</p>
                    <span
                        class="resouce-description"
                        v-html="risorsa.risorsa.description"
                    ></span>
                </div>

                <div>
                    <div
                        v-if="
                            entry.servizio &&
                                (entry.servizio.durata == 0 ||
                                    entry.servizio.durata == null) &&
                                durate[0] != 'max'
                        "
                    >
                        <label for="durata"
                            >{{ $t("resource_grid.select") }}
                        </label>

                        <select
                            name="durata"
                            id="durata"
                            @change="selectDurata"
                            class="form-select"
                            :aria-label="$t('resource_grid.selected')"
                        >
                            <option
                                v-for="(n, i) in orari"
                                :key="n.durata"
                                :value="n.durata"
                                :default="i == 0"
                                :selected="
                                    durataSelected
                                        ? n.durata == durataSelected
                                        : i == 0
                                "
                            >
                                {{ n.label }}
                            </option>
                        </select>
                    </div>
                    <div v-else>
                        {{ $t("form.header.durata") }}
                        <span v-if="durataSelezionata === 'max'">
                            {{ $t("form.header.durata-max") }}
                        </span>
                        <span v-else>
                            {{ secondsToString(entry.durata, this) }}
                        </span>
                    </div>
                    <div
                        v-if="startChanged"
                        class="mt-2 d-flex justify-content-center align-items-center"
                        style="color: #ffc107"
                    >
                        <i class="fas fa-exclamation-triangle fa-2x "></i>
                        <span class="font-weight-bold mt-2 ">
                            {{ $t("cambio_inizio.title") }}
                        </span>
                    </div>
                    <div class="info mt-3 p-2 font-weight-bold">
                        <p v-if="!error" class="">
                            {{
                                $t("resource_grid.durata", {
                                    inizio: entry.data.toLocaleTimeString([], {
                                        hour: "2-digit",
                                        minute: "2-digit"
                                    }),
                                    fine: endTime
                                })
                            }}
                        </p>
                        <div v-else class="text-danger">
                            {{ $t("resource_grid.error") }}
                        </div>
                    </div>
                </div>
            </template>
            <template slot="extra"></template>
            <template slot="footer">
                <button
                    @click="navigate"
                    @keyup.enter="navigate"
                    class="btn btn-primary"
                    :class="{ disabled: error }"
                >
                    {{ $t("buttons.next") }}
                </button>
            </template>
        </modal>
    </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { showsDurate } from "../mixins/showsDurate";
import Modal from "./Modal";
export default {
    mixins: [showsDurate],
    components: {
        Modal
    },
    props: {
        showDurataModal: {
            type: Boolean,
            required: true
        },
        time: {
            type: Object,
            required: true
        }
    },

    watch: {
        "entry.servizio.durata"() {
            if (
                this.entry.servizio &&
                (this.entry.servizio.durata == 0 ||
                    this.entry.servizio.durata == null) &&
                this.durataSelezionata != "max"
            ) {
                this.populateSelect();
                this.notifySelect = true;
            }
        },
        durataSelected(value) {
            const copy = new Date(this.originalStart.getTime());

            const end = new Date(copy.setSeconds(copy.getSeconds() + value));

            this.setDate({
                date: this.data,
                end
            });
        },
        showDurataModal() {
            this.show = this.showDurataModal;
        }
    },
    data() {
        return {
            orari: null,
            show: false,
            error: false,
            startChanged: false,
            originalStart: null,
            durata: null
        };
    },
    computed: {
        ...mapState({
            entry: state => state.Entry,
            durataSelected: state => state.Entry.durata,
            risorsa: state => state.Entry.risorsa,
            schedule: state => state.Entry.risorsa.schedule,
            exceptions: state => state.Entry.risorsa.periods,
            data: state => state.Entry.data,
            end: state => state.Entry.end,
            configs: state => state.Ente.configs
        }),
        prenotazioniInCorso() {
            return (
                this.configs.general.prenotazione_in_corso &&
                this.configs.general.prenotazione_in_corso != 0
            );
        },
        endTime() {
            const copy = new Date(this.originalStart.getTime());

            if (this.durataSelezionata == "max") {
                const durata =
                    (this.time.end.getTime() - this.time.date.getTime()) / 1000;
                this.setDurata(durata);
                return new Date(copy.setSeconds(copy.getSeconds() + durata))
                    .toLocaleTimeString("it-IT")
                    .replace(/(.*)\D\d+/, "$1");
            }

            return this.end
                .toLocaleTimeString("it-IT")
                .replace(/(.*)\D\d+/, "$1");
        }
    },

    mounted() {
        this.populateSelect();
        this.show = this.showDurataModal;

        this.originalStart = this.data;
        const slot = { date: this.data, end: this.end };
        const differenzaInMinutiOraTimeStart = Math.floor(
            Math.abs(slot.date - new Date()) / 1000 / 60
        );

        if (
            this.prenotazioniInCorso &&
            !this.dataProvvisoria &&
            slot.date < new Date() &&
            differenzaInMinutiOraTimeStart > 1
        ) {
            this.startChanged = true;
            this.setDate({ date: new Date(), end: this.end });
        }
    },
    methods: {
        ...mapMutations({
            setDurata: "Entry/SET_DURATA",
            setDate: "Entry/SET_DATE"
        }),

        selectDurata(event) {
            const durata = event.target.value;
            // this.setDurata(durata);
            if (this.checkExceptions(durata) && this.checkDay(durata)) {
                this.setDurata(durata);
                this.error = false;
            } else {
                this.error = true;
            }
        },
        formatDay(day) {
            let copy = new Date(day.getTime());
            let arr = copy.toISOString().split("T")[0];
            return arr;
        },
        checkDay(durata) {
            const day = this.formatDay(this.originalStart);

            //controllo se fine prenotazione sfonda orario fine turno
            if (this.schedule[day]) {
                const boh = this.schedule[day].map(turno => {
                    let copy = new Date(this.originalStart.getTime());
                    const prenotazioneEnd = new Date(
                        copy.setSeconds(copy.getSeconds() + durata)
                    );
                    copy = new Date(this.originalStart.getTime());
                    const endTurno = new Date(
                        copy.setHours(
                            turno.split("-")[1].split(":")[0],
                            turno.split("-")[1].split(":")[1],
                            0
                        )
                    );
                    return prenotazioneEnd > endTurno;
                });
                return boh.filter(e => e === true).length == 0;
            }
        },
        checkExceptions(durata) {
            //controllo se ci sono prenotazioni nel giorno
            if (
                this.exceptions[this.originalStart.toISOString().split("T")[0]]
            ) {
                const eccezioni = this.exceptions[
                    this.originalStart.toISOString().split("T")[0]
                ];
                const prenotazioneStart = this.entry.data;

                let intersezioni = eccezioni.filter(ecc => {
                    let copy = new Date(this.originalStart.getTime());

                    const prenotazioneEnd = new Date(
                        copy.setSeconds(copy.getSeconds() + durata)
                    );
                    copy = new Date(this.originalStart.getTime());
                    const start = new Date(
                        copy.setHours(
                            ecc.split("-")[0].split(":")[0],
                            ecc.split("-")[0].split(":")[1],
                            0
                        )
                    );
                    copy = new Date(this.originalStart.getTime());
                    const end = new Date(
                        copy.setHours(
                            ecc.split("-")[1].split(":")[0],
                            ecc.split("-")[1].split(":")[1],
                            0
                        )
                    );
                    return start < prenotazioneEnd && prenotazioneStart < end;
                });

                return intersezioni.length == 0;
            }
            return true;
        },
        navigate() {
            this.$router.push({ name: "DatiUtente" });
        }
    }
};
</script>

<style scoped>
.service-description {
    margin: auto;
    border: 1px solid #666;
    padding: 1rem 2rem;
    border-radius: 16px;
    font-size: 0.9rem;
    background-color: #c7defe;
}
</style>
