<template>
  <div class="h-100 scrollable text-center" :class="resourceGridType === 'horizontal' ? '' : 'container-fluid'"
    id="resourceGrid">
    <div class="loading h-100 mx-auto" v-if="loading">
      <video autoplay loop muted playsinline width="100px">
        <source src="../../assets/easyload-xl.webm" type="video/webm" />
      </video>
    </div>

    <!-- Probabili soluzioni:
    flex wrap 
    indicatore scroll
    -->
    <!-- <div class="col-12 position-relative z-100 w-50">
      <div class="position-sticky">yo</div>
    </div> -->

    <div v-if="risorse && risorse.length === 0 && !loading"
      class="d-flex align-items-center justify-content-center mx-auto">
      <p class="mt-5">{{ $t("form.calendario.chiusura") }}</p>
    </div>
    <div v-if="risorse && risorse.length > 0 && !loading" :class="
      resourceGridType === 'horizontal'
        ? 'h-100 d-flex flex-column'
        : 'row h-100 flex-nowrap'
    " ref="resourceGrid">
      <div v-if="allEmpty" class="p-5 mx-auto text-center">
        {{ $t("form.calendario.chiusura") }}
      </div>
      <div v-else v-for="risorsa in risorse" :key="risorsa.id" ref="grid-column" :class="
        resourceGridType === 'horizontal'
          ? 'p-0 snap d-flex'
          : 'col-sm col-sm-2 flex-column p-0 snap position-relative'
      ">
        <div class="position-sticky z-100" :class="
          resourceGridType === 'horizontal'
            ? 'left-0  start-0 calendar-bg'
            : '  calendar-bg'
        ">
          <div :class="
            resourceGridType === 'horizontal'
              ? 'p-2 border h-100 text-center '
              : 'p-2 border col-12 text-center'
          ">
            {{ risorsa.risorsa.resource_name }}
            <i class="far fa-question-circle" v-tooltip="'informazioni sulla risorsa'"
              @click="showResourceDescription(risorsa.risorsa)"
              @keyup.enter="showResourceDescription(risorsa.risorsa)"></i>
          </div>
        </div>

        <schedule-resorce-grid v-if="risorsa.schedule[formatDay(date)]" :orientation="resourceGridType"
          @showDurataModal="showModaleDurate" class="p-0 text-center col-12 border bg-white" :schedule="risorsa"
          :range="range" :date="date"></schedule-resorce-grid>
        <div v-else>{{ $t("resource_grid.chiusura") }}</div>
      </div>
      <img alt="usa lo swipe per muovere la griglia" src="../../assets/images/swipe.gif"
        class="position-absolute d-md-none mt-5" width="10px" v-if="swipe && !loading" />
      <modale-durata v-if="showDurataModal" :showDurataModal="showDurataModal" :time="selectedTime"
        @closed="resetReservation" />
      <modal v-if="showResourceDescriptionModal" @close="showResourceDescriptionModal = false">
        <template slot="body">
          <div v-html="risorsa.description"></div>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import ModaleDurata from "../ModaleDurata.vue";
import Modal from "../Modal.vue";
import ScheduleResorceGrid from "./ScheduleResourceGrid";
import { generateTimes } from "../../mixins/generateTimes";

export default {
  name: "ResourceGrid",
  components: { ScheduleResorceGrid, ModaleDurata, Modal },
  mixins: [generateTimes],
  props: {
    date: {
      type: Date,
      required: true,
    },
    loading: {
      required: true,
    },
    risorse: {
      required: true,
    },
    range: {
      type: Object,
    },
  },
  computed: {
    ...mapState({
      servizio: (state) => state.Entry.servizio,
      area: (state) => state.Entry.area,
      durata: (state) => state.Entry.durata,
    }),

    resourceGridType() {
      switch (this.servizio.resource_grid) {
        case 1:
          return "vertical";
        case 2:
          return "horizontal";
        default:
          return "vertical";
      }
    },

    selected() {
      let today = new Date();
      const copy = new Date(this.date.getTime());
      return copy.setHours(today.getHours());
    },

    allEmpty() {
      return this.risorse.every((risorsa) => {
        return risorsa.schedule.length === 0;
      });
    },
  },
  mounted() {
    this.setResourceGrid(true);
    this.setTimePicker(this.date);
  },
  watch: {
    // loading() {
    //   if (!this.loading) {
    //     this.calculateRange();
    //   }
    // },
    // risorse:{
    //   handler() {
    //     this.calculateRange();
    //   },
    //   deep : true
    // },
    // range(){
    //   console.log('cambiato giorno. aggiornato range selezione')
    //   this.selectedRange = this.range;
    // },
    date(value) {
      this.setTimePicker(value);
    },
  },
  beforeDestroy() {
    this.setTimePicker(false);
    this.setResourceGrid(false);
    // this.$emit("time-picker",this.date);
  },

  data() {
    return {
      // risorse: null,
      scrolling: false,
      showDurataModal: false,
      risorsa: null,
      showResourceDescriptionModal: false,
      swipe: false,
      dayStart: 0,
      dayEnd: 24,
      selectedTime: null,
    };
  },

  // destroyed () {
  //   document.querySelector('#scrollbar').removeEventListener('scroll', this.handleScroll);
  // },
  methods: {
    ...mapMutations({
      setTimePicker: "Utils/SET_TIME_PICKER",
      setResourceGrid: "Utils/SET_RESOURCE_GRID",
    }),
    resetReservation() {
      this.showDurataModal = false;
      this.selectedTime = null;
    },
    showModaleDurate(time) {
      this.selectedTime = time;
      this.showDurataModal = true;
    },
    handleScroll() {
      const scrollbar = this.$refs.scrollbar;
      const resourceGrid = this.$refs.resourceGrid;
      if (resourceGrid.scrollLeft + scrollbar.scrollLeft > this.lastScroll) {
        resourceGrid.scrollLeft += scrollbar.scrollLeft;
        this.lastScroll = resourceGrid.scrollLeft + scrollbar.scrollLeft;
      } else {
        resourceGrid.scrollLeft -= scrollbar.scrollLeft;
        this.lastScroll = resourceGrid.scrollLeft - scrollbar.scrollLeft;
      }
    },
    // async fetchRisorse() {
    //   // this.loading = true;
    //   const res = await axios.get(
    //     `/api/risorsa/${this.servizio.id}/${this.area.id}/${this.formatDay(
    //       new Date(this.selected)
    //     )}/${this.durata}`
    //   );

    //   this.risorse = res.data.sort((a, b) => {
    //     if (a.risorsa.order_number === null && b.risorsa.order_number == null) {
    //       return a.risorsa.resource_name - b.risorsa.resource_name;
    //     }
    //     if (a.risorsa.order_number === null) return -1;
    //     if (b.risorsa.order_number == null) return -1;
    //     if (b.risorsa.order_number > a.risorsa.order_number) return -1;

    //     if (a.risorsa.order_number > b.risorsa.order_number) {
    //       console.log(a.risorsa.order_number, b.risorsa.order_number);
    //       return 1;
    //     }
    //     return 0;
    //   });
    //   // this.loading = false;
    // },
    showResourceDescription(risorsa) {
      this.risorsa = risorsa;
      this.showResourceDescriptionModal = true;
    },
    formatDay(day) {
      let copy = new Date(day.getTime());
      // let arr = copy.toISOString().split("T")[0];
      // console.log(arr);
      // return arr;
      var mm = copy.getMonth() + 1; // getMonth() is zero-based
      var dd = copy.getDate();

      return [
        copy.getFullYear(),
        (mm > 9 ? "" : "0") + mm,
        (dd > 9 ? "" : "0") + dd,
      ].join("-");
    },

    // onScroll(e, direction) {
    //   const elementoScrollato = this.$refs.resourceGrid;
    //   if (direction == "left") {
    //     elementoScrollato.scrollLeft -= Math.ceil(
    //       elementoScrollato.offsetWidth
    //     );
    //   } else if (direction == "right") {
    //     elementoScrollato.scrollLeft += Math.ceil(
    //       elementoScrollato.offsetWidth
    //     );
    //   }
    // },
  },
};
</script>

<style scoped>
.scroll {
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}

.scrollable {
  overflow-x: scroll;
  max-height: 70vh;
  /* min-height: 100%; */
}

.calendar-bg {
  background-color: #eee;
}

.z-100 {
  z-index: 100;
}

.orari {
  background-color: red;
  position: fixed;
  top: 0;
  left: 0;
}

.snap {
  scroll-snap-align: start;
}

.scrollbox {
  overflow: scroll;
  scroll-behavior: smooth;
}

@media screen and(max-width: 600px) {
  .scrollable::-webkit-scrollbar {
    display: none;
  }
}

.col-sm {
  width: 29%;
}

.z-1000 {
  z-index: 1000;
}
</style>

