var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.schedule)?_c('div',{staticClass:"d-flex flex-column w-100",attrs:{"data-tour-step":"9","data-cypress":"time-picker"}},[(_vm.servizio.multi_prenotazione != 0)?_c('div',{staticClass:"number_of_reservation_selector"},[_c('label',{attrs:{"for":"#person-mumber"}},[_vm._v(" "+_vm._s(_vm.$t('multi_prenotazione.domanda'))+" ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.reservationsNumber),expression:"reservationsNumber"}],staticClass:"time-pill ml-2",attrs:{"name":"person-mumber","id":"person-mumber"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.reservationsNumber=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.callSetReservationsNumber()}]}},_vm._l((_vm.numeroRisorse),function(n){return _c('option',{key:n},[_vm._v(_vm._s(n))])}),0)]):_vm._e(),(_vm.schedule && _vm.isEmpty(_vm.availableTimes))?_c('div',{staticClass:"w-100 d-flex justify-content-center align-items-center mt-5"},[_vm._v(" "+_vm._s(_vm.$t("form.calendario.nessun_orario") == "form.calendario.nessun_orario" ? "Non è più possibile prenotare in questa giornata, il servizio è chiuso" : _vm.$t("form.calendario.nessun_orario"))+" ")]):_vm._e(),(_vm.loading || !_vm.schedule)?_c('loading-spinner',{staticClass:"loading",attrs:{"showText":"","tabindex":"0"}}):[(!_vm.durata)?_c('label',{staticClass:"mx-auto m-5",attrs:{"for":"durata"}},[_vm._v(" "+_vm._s(_vm.$t("form.calendario.time_picker.seleziona_durata"))+" ")]):[(
                    _vm.sch[_vm.formatDay(_vm.date)] == undefined ||
                    _vm.sch[_vm.formatDay(_vm.date)][0] == '00:00-24:00'
                )?_c('div',{staticClass:"d-flex align-items-center justify-content-center mx-auto"},[_c('p',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.$t("form.calendario.chiusura")))])]):(_vm.autoSelectDate)?[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('clicca per prenotare'),expression:"'clicca per prenotare'"}],staticClass:"chip text-white disponibile m-5 w-100",style:({
                        backgroundColor: '#666',
                    }),attrs:{"aria-labelledby":_vm.times.filter(
                            function (e) { return e != undefined && e.booked == false; }
                        )[0],"tabindex":"0"},on:{"click":function($event){_vm.selectDate(
                            _vm.times.filter(
                                function (e) { return e != undefined && e.booked == false; }
                            )[0]
                        )},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.selectDate(
                            _vm.times.filter(
                                function (e) { return e != undefined && e.booked == false; }
                            )[0]
                        )}}},[_vm._v(" "+_vm._s(_vm.times .filter( function (e) { return e != undefined && e.booked == false; } )[0] .date.toLocaleTimeString("it-IT") .replace(/(.*)\D\d+/, "$1"))+" "),(
                            _vm.configs &&
                            _vm.configs.griglia &&
                            _vm.configs.griglia.mostra_ora_fine &&
                            _vm.configs.griglia.mostra_ora_fine != 0
                        )?[_vm._v(" - "+_vm._s(_vm.endTime( _vm.times.filter( function (e) { return e != undefined && e.booked == false; } )[0].date ))+" ")]:_vm._e()],2)]:(_vm.times)?_c('div',{staticClass:"time-picker d-flex justify-content-center align-items-center flex-wrap p-5",attrs:{"aria-label":"time select"}},_vm._l((_vm.times),function(time,i){return _c('div',{key:i,class:[
                        _vm.times.filter(function (e) { return e != null; }).length != 0
                            ? ''
                            : 'd-none' ],staticStyle:{"display":"flex","flex-direction":"column","justify-content":"center","align-items":"center"}},[(time && time.date)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                            !_vm.prenotazioniContemporanee && time.reserved
                                ? _vm.$t('time_picker.prenotazione_in_corso')
                                : time.booked
                                ? _vm.$t('time_picker.occupied')
                                : _vm.$t('time_picker.book')
                        ),expression:"\n                            !prenotazioniContemporanee && time.reserved\n                                ? $t('time_picker.prenotazione_in_corso')\n                                : time.booked\n                                ? $t('time_picker.occupied')\n                                : $t('time_picker.book')\n                        "}],staticClass:"chip text-white",class:{
                            occupato: time.booked,
                            'not-available': !time.available,
                            'not-available':
                                !_vm.prenotazioniContemporanee && time.reserved,
                            disponibile: !time.booked && time.available,
                            reserved: time.reserved,
                        },style:({
                            backgroundColor:
                                time.booked || !time.available
                                    ? '#ccc'
                                    : '#666',
                        }),attrs:{"tabindex":"0","title":!_vm.prenotazioniContemporanee && time.reserved
                                ? _vm.$t('time_picker.prenotazione_in_corso')
                                : time.booked
                                ? _vm.$t('time_picker.occupied')
                                : _vm.$t('time_picker.book'),"aria-label":time.date
                                .toLocaleTimeString('it-IT')
                                .replace(/(.*)\D\d+/, '$1') +
                            (!_vm.prenotazioniContemporanee && time.reserved
                                ? _vm.$t('time_picker.prenotazione_in_corso')
                                : time.booked
                                ? _vm.$t('time_picker.occupied')
                                : _vm.$t('time_picker.book'))},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.selectDate(time)},"click":function($event){return _vm.selectDate(time)}}},[_c('span',[_vm._v(" "+_vm._s(time.date .toLocaleTimeString("it-IT") .replace(/(.*)\D\d+/, "$1"))+" "),(
                                    _vm.configs &&
                                    _vm.configs.griglia &&
                                    _vm.configs.griglia.mostra_ora_fine &&
                                    _vm.configs.griglia.mostra_ora_fine != 0
                                )?[_vm._v(" - "+_vm._s(time.end .toLocaleTimeString("it-IT") .replace(/(.*)\D\d+/, "$1")))]:_vm._e()],2),(
                                _vm.configs &&
                                _vm.configs.portale_pubblico &&
                                _vm.configs.portale_pubblico
                                    .mostra_numero_risorse_libere &&
                                _vm.configs.portale_pubblico
                                    .mostra_numero_risorse_libere != 0
                            )?[_c('span',{staticClass:"text-small",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.$t("numero_risorse", { disponibili: time.show_disponibili, totale_risorse: time.show_su, }))+" ")])]:_vm._e()],2):_vm._e()])}),0):_c('div',{staticClass:"d-flex align-items-center justify-content-center mx-auto"},[_c('p',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.$t("form.calendario.chiusura")))])]),(_vm.showModaleUpdateStart)?_c('modal',{on:{"close":_vm.resetModaleUpdateStart}},[_c('template',{slot:"body"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("cambio_inizio.title"))+" ")]),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-12 col-md-5"},[_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("cambio_inizio.from"))+" ")]),_c('div',{staticClass:"chip text-white p-3",staticStyle:{"background-color":"#b11616","line-height":"16px"}},[_vm._v(" "+_vm._s(_vm.dataProvvisoria.original .toLocaleTimeString("it-IT") .replace(/(.*)\D\d+/, "$1"))+" "),(
                                            _vm.configs &&
                                            _vm.configs.griglia &&
                                            _vm.configs.griglia
                                                .mostra_ora_fine &&
                                            _vm.configs.griglia
                                                .mostra_ora_fine != 0
                                        )?[_vm._v(" - "+_vm._s(_vm.dataProvvisoria.end .toLocaleTimeString("it-IT") .replace(/(.*)\D\d+/, "$1"))+" ")]:_vm._e()],2)])]),_c('span',{staticClass:"p-2 col-2 d-none d-md-flex justify-content-center align-items-center",staticStyle:{"align-self":"flex-end","margin-bottom":"5px"}},[_c('i',{staticClass:"fas fa-arrow-right"})]),_c('span',{staticClass:"p-2 col-12 d-md-none d-flex justify-content-center",staticStyle:{"align-self":"flex-end","margin-bottom":"5px"}},[_c('i',{staticClass:"fas fa-arrow-down"})]),_c('div',{staticClass:"col-12 col-md-5"},[_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("cambio_inizio.to"))+" ")]),_c('div',{staticClass:"chip text-white p-3",staticStyle:{"background-color":"#056107","line-height":"16px"}},[_vm._v(" "+_vm._s(_vm.dataProvvisoria.date .toLocaleTimeString("it-IT") .replace(/(.*)\D\d+/, "$1"))+" "),(
                                            _vm.configs &&
                                            _vm.configs.griglia &&
                                            _vm.configs.griglia
                                                .mostra_ora_fine &&
                                            _vm.configs.griglia
                                                .mostra_ora_fine != 0
                                        )?[_vm._v(" - "+_vm._s(_vm.dataProvvisoria.end .toLocaleTimeString("it-IT") .replace(/(.*)\D\d+/, "$1"))+" ")]:_vm._e()],2)])])])]),_c('template',{slot:"footer"},[_c('e-button',{on:{"click":function($event){return _vm.selectDate(_vm.dataProvvisoria)}}},[_vm._v(_vm._s(_vm.$t("buttons.confirm")))]),_c('e-button',{attrs:{"danger":true},on:{"click":_vm.resetModaleUpdateStart}},[_vm._v(_vm._s(_vm.$t("buttons.annulla")))])],1)],2):_vm._e()]]],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }