<template>
    <div :class="orientation === 'horizontal' ? 'd-flex' : ''">
        <div v-for="(time, i) in filteredTimes" :key="i">
            <div
                v-if="time && time.date"
                :class="[
                    {
                        occupato: time.booked,
                        past: time.past,
                        disponibile: !time.booked && time.available,
                        reserved: time.reserved,
                        booked: time.booked
                    },
                    orientation === 'horizontal' ? 'cell-horizontal' : 'cell'
                ]"
                class="border"
                tabindex="0"
                :ref="time.now"
                @click="selectDate(time)"
                @keyup.enter="selectDate(time)"
            >
                {{
                    time.date
                        .toLocaleTimeString("it-IT")
                        .replace(/(.*)\D\d+/, "$1")
                }}
                <template
                    v-if="
                        configs &&
                            configs.griglia &&
                            configs.griglia.mostra_ora_fine &&
                            configs.griglia.mostra_ora_fine != 0
                    "
                >
                    -
                    {{
                        time.end
                            .toLocaleTimeString("it-IT")
                            .replace(/(.*)\D\d+/, "$1")
                    }}</template
                >
            </div>
        </div>
    </div>
</template>

<script>
import { generateTimes } from "../../mixins/generateTimes";
import { timeUtils } from "../../mixins/timeUtils";
import { mapMutations, mapState } from "vuex";

export default {
    name: "ScheduleResourceGrid",
    mixins: [timeUtils, generateTimes],
    props: {
        date: {
            type: Date,
            required: true
        },
        schedule: {
            type: Object,
            required: true
        },
        orientation: {
            type: String,
            required: true
        },
        range: {
            required: true
        }
    },
    data() {
        return {
            filteredTimes: []
        };
    },
    mounted() {
        this.focusFirstCell();
        // this.filteredTimes = this.times.filter(
        //       (e) => e.date >= this.range.start && e.end <= this.range.end
        //     );
        // // //se range è settato
        if (this.range.start && this.range.end) {
            this.filteredTimes = this.times.filter(
                e => e.date >= this.range.start && e.end <= this.range.end
            );
        } else this.filteredTimes = this.times;
    },
    computed: {
        ...mapState({
            durata: state => state.Entry.durata,
            reservations: state => state.User.reservations,
            servizio: state => state.Entry.servizio,
            area: state => state.Entry.area,
            configs: state => state.Ente.configs,
            autoSelectDate: state => state.Entry.autoSelectDate
        }),

        sch() {
            return this.schedule.schedule;
        },
        oggi: () => new Date(),
        nextDays() {
            let copy = new Date(this.date.getTime());
            return Object.keys(this.sch)[copy.getDay() - 1];
        },
        exceptions() {
            if (
                this.schedule.exceptions[this.date.toISOString().split("T")[0]]
            ) {
                return this.schedule.exceptions[
                    this.date.toISOString().split("T")[0]
                ].map(e => {
                    const startHour = e.split("-")[0].split(":")[0];
                    const startMinutes = e.split("-")[0].split(":")[1];
                    const endHour = e.split("-")[1].split(":")[0];
                    const endMinutes = e.split("-")[1].split(":")[1];
                    const copy = new Date(this.date.getTime());
                    return {
                        start: new Date(
                            new Date(copy.setHours(startHour)).setMinutes(
                                startMinutes
                            )
                        ),
                        end: new Date(
                            new Date(copy.setHours(endHour)).setMinutes(
                                endMinutes
                            )
                        )
                    };
                });
            } else return null;
        },

        reserv() {
            if (
                this.reservations &&
                this.reservations[this.date.toISOString().split("T")[0]]
            ) {
                return this.reservations[
                    this.date.toISOString().split("T")[0]
                ].map(e => {
                    const startHour = e.split("-")[0].split(":")[0];
                    const startMinutes = e.split("-")[0].split(":")[1];
                    const endHour = e.split("-")[1].split(":")[0];
                    const endMinutes = e.split("-")[1].split(":")[1];
                    return {
                        start: new Date(
                            new Date(this.date.setHours(startHour)).setMinutes(
                                startMinutes
                            )
                        ),
                        end: new Date(
                            new Date(this.date.setHours(endHour)).setMinutes(
                                endMinutes
                            )
                        )
                    };
                });
            } else return null;
        },

        orari() {
            if (this.sch[this.formatDay(this.date)]) {
                return Array.from(this.sch[this.formatDay(this.date)]);
            }
            return null;
        },
        durataSecondi() {
            if (this.servizio.servizio_breve != 0) {
                let inizio = this.date.setHours(
                    ...this.sch[this.formatDay(this.date)][0]
                        .split("-")[0]
                        .split(":")
                );
                let ora_fine = this.sch[this.formatDay(this.date)][
                    this.sch[this.formatDay(this.date)].length - 1
                ].split("-")[1];
                const fine = this.date.setHours(...ora_fine.split(":"));
                if (new Date() > inizio) inizio = new Date();
                return Math.ceil((fine - inizio) / 1000);
            }
            if (this.durata === "max") {
                return "max";
            }
            // if (typeof this.durata != "number") {
            //   return this.durata.split(" ")[0] * 60;
            // }
            return this.durata;
        }
    },
    watch: {
        range: {
            handler() {
                this.filteredTimes = this.times.filter(
                    e => e.date >= this.range.start && e.end <= this.range.end
                );
            },
            deep: true
        }
    },
    methods: {
        focusFirstCell() {
            if (this.$refs.now) {
                if (this.$refs.not_now && this.$refs.not_now.length > 4) {
                    this.$refs.not_now[
                        this.$refs.not_now.length - 3
                    ].scrollIntoView();
                }
                //  this.$refs.now[4].scrollTop += 200;
            }
        },

        calculateSlotEndTime(date) {
            const copy = new Date(date.getTime());
            return new Date(copy.setSeconds(copy.getSeconds() + this.durata))
                .toLocaleTimeString("it-IT")
                .replace(/(.*)\D\d+/, "$1");
        },
        ...mapMutations({
            setDate: "Entry/SET_DATE",
            setRisorsa: "Entry/SET_RISORSA"
        }),
        selectDate(time) {
            this.setRisorsa(this.schedule);
            this.setDate(time);

            this.$emit("showDurataModal", time);
            // this.$router.push({ name: "DatiUtente" });
        }
    }
};
</script>

<style>
.disponibile {
    cursor: pointer;
}

.occupato,
.not-available {
    pointer-events: none;
}

.booked {
    background-color: #d33939 !important;
    color: white;
}

.past {
    background-color: #ccc !important;
    pointer-events: none;
}

.cell-horizontal {
    height: 100%;
    width: 100px;
    background-color: white;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.cell {
    margin: 0.3rem;
    background-color: white;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}
</style>
