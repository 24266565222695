var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.show)?_c('modal',{attrs:{"data-cypress":"area-modal"},on:{"close":function($event){return _vm.$emit('closed')}}},[_c('template',{slot:"body"},[_c('div',{staticClass:"descrition service-description my-3"},[_c('div',[_vm._v(_vm._s(_vm.$t("resource_grid.selected")))]),_c('p',[_vm._v(_vm._s(_vm.risorsa.risorsa.resource_name))]),_c('span',{staticClass:"resouce-description",domProps:{"innerHTML":_vm._s(_vm.risorsa.risorsa.description)}})]),_c('div',[(
                        _vm.entry.servizio &&
                            (_vm.entry.servizio.durata == 0 ||
                                _vm.entry.servizio.durata == null) &&
                            _vm.durate[0] != 'max'
                    )?_c('div',[_c('label',{attrs:{"for":"durata"}},[_vm._v(_vm._s(_vm.$t("resource_grid.select"))+" ")]),_c('select',{staticClass:"form-select",attrs:{"name":"durata","id":"durata","aria-label":_vm.$t('resource_grid.selected')},on:{"change":_vm.selectDurata}},_vm._l((_vm.orari),function(n,i){return _c('option',{key:n.durata,attrs:{"default":i == 0},domProps:{"value":n.durata,"selected":_vm.durataSelected
                                    ? n.durata == _vm.durataSelected
                                    : i == 0}},[_vm._v(" "+_vm._s(n.label)+" ")])}),0)]):_c('div',[_vm._v(" "+_vm._s(_vm.$t("form.header.durata"))+" "),(_vm.durataSelezionata === 'max')?_c('span',[_vm._v(" "+_vm._s(_vm.$t("form.header.durata-max"))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.secondsToString(_vm.entry.durata, this))+" ")])]),(_vm.startChanged)?_c('div',{staticClass:"mt-2 d-flex justify-content-center align-items-center",staticStyle:{"color":"#ffc107"}},[_c('i',{staticClass:"fas fa-exclamation-triangle fa-2x "}),_c('span',{staticClass:"font-weight-bold mt-2 "},[_vm._v(" "+_vm._s(_vm.$t("cambio_inizio.title"))+" ")])]):_vm._e(),_c('div',{staticClass:"info mt-3 p-2 font-weight-bold"},[(!_vm.error)?_c('p',{},[_vm._v(" "+_vm._s(_vm.$t("resource_grid.durata", { inizio: _vm.entry.data.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }), fine: _vm.endTime }))+" ")]):_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t("resource_grid.error"))+" ")])])])]),_c('template',{slot:"extra"}),_c('template',{slot:"footer"},[_c('button',{staticClass:"btn btn-primary",class:{ disabled: _vm.error },on:{"click":_vm.navigate,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.navigate($event)}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.next"))+" ")])])],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }