import { mapMutations, mapState } from "vuex";

export const range = {
    computed: {
        ...mapState({
            range: state => state.Utils.range,
            date: state => state.Utils.timePicker
        })
    },
    methods: {
        ...mapMutations({
            setRange: "Utils/SET_RANGE"
        }),
        calculateRange() {
            // start è uguale alle 00 del giorno selezionato
            let copy = new Date(this.date.getTime());
            let start = copy.setHours(0);
            //end è uguale alla mezzanotte del giorno selezionato
            copy = new Date(this.date.getTime());
            let end = copy.setHours(24);
            if (!this.schedule)
                return (this.range = { start: start, end: end });
            const startDates = this.schedule
                .map(r => this.getStart(r.schedule[this.formatDay(this.date)]))
                ;
            const endDates = this.schedule
                .map(r => this.getEnd(r.schedule[this.formatDay(this.date)]))
                ;

            var dayStart = new Date(Math.min.apply(null, startDates));
            var dayEnd = new Date(Math.max.apply(null, endDates));
            start = new Date(start).setTime(dayStart.getTime());
            end = new Date(end).setTime(dayEnd.getTime());
            this.setRange({
                start,
                end
            });
        },

        getStart(p) {
            if (Array.isArray(p)) {
                p = p[0];
            }
            const hour = p.split("-")[0].split(":")[0];
            const minutes = p.split("-")[0].split(":")[1];
            return new Date(
                new Date(new Date(this.date.setHours(hour)).setMinutes(minutes))
            );
        },
        getEnd(p) {
            if (Array.isArray(p)) {
                p = p[p.length - 1];
            }
            const hour = p.split("-")[1].split(":")[0];
            const minutes = p.split("-")[1].split(":")[1];
            return new Date(
                new Date(new Date(this.date.setHours(hour)).setMinutes(minutes))
            );
        },
        formatDay(day) {
            let copy = new Date(day.getTime());

            var mm = copy.getMonth() + 1; // getMonth() is zero-based
            var dd = copy.getDate();

            return [
                copy.getFullYear(),
                (mm > 9 ? "" : "0") + mm,
                (dd > 9 ? "" : "0") + dd,
            ].join("-");
        },
    }
};
