

export const timeUtils = {
  methods: {

    timeStringToDate(timeString, i = 0) {
      const hour = timeString.split("-")[i].split(":")[0];
      const minutes = timeString.split("-")[i].split(":")[1];
      return new Date(
        new Date(new Date(this.date.setHours(hour)).setMinutes(minutes))
      );
    },
    timePeriodStringToDate(timePeriodString) {
      return [this.timeStringToDate(timePeriodString), this.timeStringToDate(timePeriodString, 1)]
    },
    endTime(time) {
      const copy = new Date(time.getTime());
      console.log(this.durataSecondi)
      return new Date(copy.setSeconds(copy.getSeconds() + this.durataSecondi))
        .toLocaleTimeString("it-IT")
        .replace(/(.*)\D\d+/, "$1");
    },
    firstTime(i, orari = null) {
      if (orari == null) orari = this.orari;

      const hour = orari[i].split("-")[0].split(":")[0];
      const minutes = orari[i].split("-")[0].split(":")[1];
      return new Date(
        new Date(new Date(this.date.setHours(hour)).setMinutes(minutes))
      );
    },
    lastTime(i, orari = null) {
      if (orari == null) orari = this.orari;
      // console.log(this.orari);
      const hour = orari[i].split("-")[1].split(":")[0];
      const minutes = orari[i].split("-")[1].split(":")[1];
      const copy = new Date(this.date.getTime());
      return new Date(
        new Date(new Date(copy.setHours(hour)).setMinutes(minutes))
      );
    },
    formatDay(day) {
      //return format yyyy-mm-dd
      const offset = day.getTimezoneOffset()
      day = new Date(day.getTime() - (offset * 60 * 1000))
      let copy = new Date(day.getTime());
      let arr = copy.toISOString().split("T")[0];
      return arr;
    },
    isEmpty(arr) {
      return arr.toString().replace(/,/g, "") === "";
    },
  }
};
