var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100 scrollable text-center",class:_vm.resourceGridType === 'horizontal' ? '' : 'container-fluid',attrs:{"id":"resourceGrid"}},[(_vm.loading)?_c('div',{staticClass:"loading h-100 mx-auto"},[_c('video',{attrs:{"autoplay":"","loop":"","muted":"","playsinline":"","width":"100px"},domProps:{"muted":true}},[_c('source',{attrs:{"src":require("../../assets/easyload-xl.webm"),"type":"video/webm"}})])]):_vm._e(),(_vm.risorse && _vm.risorse.length === 0 && !_vm.loading)?_c('div',{staticClass:"d-flex align-items-center justify-content-center mx-auto"},[_c('p',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.$t("form.calendario.chiusura")))])]):_vm._e(),(_vm.risorse && _vm.risorse.length > 0 && !_vm.loading)?_c('div',{ref:"resourceGrid",class:_vm.resourceGridType === 'horizontal'
      ? 'h-100 d-flex flex-column'
      : 'row h-100 flex-nowrap'},[(_vm.allEmpty)?_c('div',{staticClass:"p-5 mx-auto text-center"},[_vm._v(" "+_vm._s(_vm.$t("form.calendario.chiusura"))+" ")]):_vm._l((_vm.risorse),function(risorsa){return _c('div',{key:risorsa.id,ref:"grid-column",refInFor:true,class:_vm.resourceGridType === 'horizontal'
        ? 'p-0 snap d-flex'
        : 'col-sm col-sm-2 flex-column p-0 snap position-relative'},[_c('div',{staticClass:"position-sticky z-100",class:_vm.resourceGridType === 'horizontal'
          ? 'left-0  start-0 calendar-bg'
          : '  calendar-bg'},[_c('div',{class:_vm.resourceGridType === 'horizontal'
            ? 'p-2 border h-100 text-center '
            : 'p-2 border col-12 text-center'},[_vm._v(" "+_vm._s(risorsa.risorsa.resource_name)+" "),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('informazioni sulla risorsa'),expression:"'informazioni sulla risorsa'"}],staticClass:"far fa-question-circle",on:{"click":function($event){return _vm.showResourceDescription(risorsa.risorsa)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.showResourceDescription(risorsa.risorsa)}}})])]),(risorsa.schedule[_vm.formatDay(_vm.date)])?_c('schedule-resorce-grid',{staticClass:"p-0 text-center col-12 border bg-white",attrs:{"orientation":_vm.resourceGridType,"schedule":risorsa,"range":_vm.range,"date":_vm.date},on:{"showDurataModal":_vm.showModaleDurate}}):_c('div',[_vm._v(_vm._s(_vm.$t("resource_grid.chiusura")))])],1)}),(_vm.swipe && !_vm.loading)?_c('img',{staticClass:"position-absolute d-md-none mt-5",attrs:{"alt":"usa lo swipe per muovere la griglia","src":require("../../assets/images/swipe.gif"),"width":"10px"}}):_vm._e(),(_vm.showDurataModal)?_c('modale-durata',{attrs:{"showDurataModal":_vm.showDurataModal,"time":_vm.selectedTime},on:{"closed":_vm.resetReservation}}):_vm._e(),(_vm.showResourceDescriptionModal)?_c('modal',{on:{"close":function($event){_vm.showResourceDescriptionModal = false}}},[_c('template',{slot:"body"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.risorsa.description)}})])],2):_vm._e()],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }